

import React, { useEffect, useRef } from 'react';

// Material UI Components
import ApexCharts from 'apexcharts';

function EventChart() {
    const eventChart = useRef(null);
    let chartInstance = useRef(null);

    const equip1 = new Date('2019-03-02').getTime()
    const equip2 = new Date('2019-03-04').getTime()
    const equip3 = new Date('2019-03-08').getTime()
    const equip4 = new Date('2019-03-12').getTime()
    const equip5 = new Date('2019-03-18').getTime()

    useEffect(() => {
        if (eventChart.current) {
            var options = {
                series: [
                    {
                        data: [
                            {
                                x: 'Equipment 1',
                                y: [
                                    equip1,
                                    equip2
                                ]
                            },
                            {
                                x: 'Equipment 2',
                                y: [
                                    equip2,
                                    equip3
                                ]
                            },
                            {
                                x: 'Equipment 3',
                                y: [
                                    equip3,
                                    equip4
                                ]
                            },
                            {
                                x: 'Equipment 4',
                                y: [
                                    equip4,
                                    equip5
                                ]
                            }
                        ]
                    }
                ],
                chart: {
                    height: 350,
                    zoom: false,
                    type: 'rangeBar'
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        style: {
                            colors: '#f5f5f5' // Light color for x-axis labels
                        }
                    }
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#f5f5f5' // Light color for y-axis labels
                        }
                    }
                },

                tooltip: {
                    theme: 'dark' // Dark theme for tooltips
                },


            };

            try {
                if (!chartInstance.current) {
                    chartInstance.current = new ApexCharts(eventChart.current, options);
                    chartInstance.current.render();
                }
            } catch (error) {
                console.error('Error rendering chart:', error);
            }

            return () => {
                // if (chartInstance.current) {
                //     chartInstance.current.destroy();
                //     chartInstance.current = null; // Clear the reference after destruction
                // }
            };
        }

    }, [])


    return (
        <div
            data-testid="eventChart"
            ref={eventChart}
            style={{ height: '180px' }}
        />
    )
}

export default EventChart;