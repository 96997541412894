import React, { useEffect, useState } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// Material Icons
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BugReportIcon from '@mui/icons-material/BugReport';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";
import { CardActionArea } from '@mui/material';

import AlertsChart from '../../../components/apex/AlertsChart';
import OutagesChart from '../../../components/apex/OutagesChart';
import StatusChart from '../../../components/apex/StatusChart';

/**
 * Home Component
 * @returns {JSX.Element} The rendered Home Component.
 */
function Home() {
    const [loading, setLoading] = useState(true);

    const [time, setGraphTime] = useState("24");

    const [companyList, GetCompanies] = useState([]);
    const [facilityList, GetFacilities] = useState([]);
    const [systemList, GetSystems] = useState([]);

    const [system, setSystem] = React.useState(null);

    const [alerts, setAlerts] = React.useState(null);


    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];


    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                console.log(response.data);
                GetCompanies(response.data);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });
        axiosInstance
            .get(`/alerts`).then(response => {
                console.log(response.data);
                console.log("Alerts fetched Successfully");
                setAlerts(response.data);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])


    companyList?.map((company) => {
        companySearch.push(
            {
                label: company.company_name,
                companyObject: company
            })
    })


    facilityList?.map((facility) => {
        facilitySearch.push(
            {
                label: facility.facility_name,
                facilityObject: facility
            })
    })


    systemList?.map((facility) => {
        systemSearch.push(
            {
                label: facility.system_name,
                facilityObject: system
            })
    })


    function graphTime(event) {
        setGraphTime(event.target.value);
    }

    let criticalCount = 0;
    let errorCount = 0;
    let warningCount = 0;
    let activeCount = 0;

    let criticalActive = false;
    let errorActive = false;
    let warningActive = false;
    let active = false;

    alerts?.forEach(alert => {
        criticalCount += alert.status.critical.count;
        errorCount += alert.status.error.count;
        warningCount += alert.status.warning.count;
        activeCount += alert.status.active.count;

        criticalActive = criticalActive || alert.status.critical.active;
        errorActive = errorActive || alert.status.error.active;
        warningActive = warningActive || alert.status.warning.active;
        active = active || alert.status.active.active;

    });

    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Grid container>
                            <Grid
                                size={{
                                    xs: 10
                                }}>
                                <Typography variant="h3">
                                    Home
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 2
                                }}>
                                <Select
                                    onChange={graphTime}
                                    sx={{
                                        background: "#e7f1fe",
                                        borderRadius: "15px",
                                    }}
                                    value={time}
                                    defaultValue="24">
                                    <MenuItem value="24h">
                                        Last 24 Hours
                                    </MenuItem>
                                    <MenuItem value="1w">
                                        Last Week
                                    </MenuItem>
                                    <MenuItem value="1m">
                                        Last Month
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardActionArea>
                            <CardContent>
                                <Typography variant="h5">
                                    Critical
                                </Typography>
                                <BugReportIcon style={{ color: "#fc5842" }} />
                                <Typography variant="h2">
                                    {criticalCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardActionArea>

                            <CardContent>
                                <Typography variant="h5">
                                    Error
                                </Typography>
                                <ErrorIcon style={{ color: "#fc5842" }} />
                                <Typography variant="h2">
                                    {errorCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardActionArea>

                            <CardContent>
                                <Typography variant="h5">
                                    Warning
                                </Typography>
                                <WarningIcon style={{ color: "#ffc107" }} />

                                <Typography variant="h2">
                                    {warningCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardActionArea>

                            <CardContent>
                                <Typography variant="h5">
                                    Active
                                </Typography>
                                <CheckCircleIcon style={{ color: "#2de377" }} />
                                <Typography variant="h2">
                                    {activeCount}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px",
                        background: "#242436",
                    }}>
                    <Table
                        sx={{
                            minWidth: 650,
                            "& .MuiTableCell-root": {
                                borderBottom: "1px solid #a4a4b6", // Border for table cells
                                color: "#fff"
                            }
                        }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>icon</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>icon</TableCell>
                                <TableCell>Equipment 1</TableCell>
                                <TableCell>Critical: High Temp</TableCell>
                                <TableCell>{new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>icon</TableCell>
                                <TableCell>Equipment 2</TableCell>
                                <TableCell>Critical: High Temp</TableCell>
                                <TableCell>{new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>icon</TableCell>
                                <TableCell>Equipment 3</TableCell>
                                <TableCell>Critical: High Temp</TableCell>
                                <TableCell>{new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Status
                            </Typography>
                            <Container>
                                <StatusChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Alerts
                            </Typography>
                            <Container>
                                <AlertsChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 6,
                    xs: 6
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Outages
                            </Typography>
                            <Container>
                                <OutagesChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Typography variant="h5">
                                Uptime
                            </Typography>
                            <Typography variant="h5">
                                uptime percentage, total uptime,
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Home;
