import { SET_ECHO_USER, UPDATE_ECHO_USER } from '../actionTypes';

// Initial state of the store for user info
const DEFAULT_STATE = {

    // isAuthenticated: false,
    access_token: '',
    echoUser: {},
    profile: {}
}

const currentEchoState = (state = DEFAULT_STATE, action) => {

    switch (action.type) {

        case SET_ECHO_USER:
            return {
                ...state,
                // isAuthenticated: action.payload.echoUser.isAuthenticated,
                access_token: action.payload.echoUser.token,
                echoUser: action.payload.echoUser.customer,
                profile: action.payload.echoUser.profile,
            };
        case UPDATE_ECHO_USER:
            return {
                ...state,
                echoUser: { ...state.echoUser, ...action.payload.echoUser }
            };
        default:
            return state;
    }
}

export default currentEchoState;