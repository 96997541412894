import React, { useEffect, useRef } from 'react';

import ApexCharts from 'apexcharts';

function BudgetChart() {
    const budgetChart = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        const options = {
            chart: {
                type: 'area',
                height: 250,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            },
            yaxis: {
                title: {
                    text: 'Cost',
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                },
            },
        };

        const series = [{
            name: 'Data Series 1',
            data: [31, 40, 28, 51, 42, 109, 100, 80, 95],
        }];

        try {
            if (!chartInstance.current) {
                chartInstance.current = new ApexCharts(budgetChart.current, { series, ...options });
                chartInstance.current.render();
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chartInstance.current) {
            //     chartInstance.current.destroy();
            //     chartInstance.current = null; // Clear the reference after destruction
            // }
        };
    }, [budgetChart.current]);
    return (
        <div
            data-testid="budgetChart"
            ref={budgetChart}
            style={{
                height: '180px'
            }} />
    )
}

export default BudgetChart;