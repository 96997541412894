import React, { useEffect, useState } from 'react';

// Material UI Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';


// Axios API
import axiosInstance from "../utils/axiosInstance";


// React Redux
import { useSelector } from "react-redux";


function AddCustomerPanel() {

    const currentUser = useSelector((state) => state.currentUser);
    const [value, setValue] = React.useState(0);

    useEffect(() => {

    }, [])
    // console.log(currentUser)
    // const dispatch = useDispatch();
    // const [profile, setProfile] = useState([]);

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [signInEmail, setSigninEmail] = useState("");
    const [signInPassword, setSigninPassword] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerCompany, setCustomerCompany] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerBirthday, setCustomerBirthday] = useState("");


    const handleSignInCustomer = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (name === "signInEmail") {
            setSigninEmail(value);
            if (!isValidEmail(value)) {
                setEmailError("Invalid Email");
            } else {
                setEmailError("");
            }
        }

        if (name === "signInPassword") {
            setSigninPassword(value);
            if (!isValidPassword(value)) {
                setPasswordError("Password must be at least 8 characters long and contain at least one number");
            } else {
                setPasswordError("");
            }
        }
    }

    const isValidEmail = (email) => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailRegex = /^[^\s@]+@coldstoragetech\.com$/;
        return emailRegex.test(email);
    }

    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    }

    const handleCustomerName = (event) => {
        setCustomerName(event.target.value);
    }

    const handleCustomerCompany = (event) => {
        setCustomerCompany(event.target.value);
    }

    const handleCustomerPhone = (event) => {
        setCustomerPhone(event.target.value);
    }

    const handleCustomerAddress = (event) => {
        setCustomerAddress(event.target.value);
    }

    const handleCustomerBirthday = (event) => {
        setCustomerBirthday(event.target.value);
    }

    const handleSignInSubmit = async (e) => {

        e.preventDefault();

        if (!isValidEmail(signInEmail)) {
            setEmailError("Invalid Email");
            return;
        }

        if (!isValidPassword(signInPassword)) {
            setPasswordError("Password must be at least 8 characters long and contain at least one number");
            return;
        }

        if (emailError || passwordError) {
            alert('Please fix the errors before submitting');
            return;
        }

        try {
            // Cookies.remove('customerToken');
            // Cookies.remove('refreshToken');
            const response = await axiosInstance.post("/auth/customer/create", {
                email: signInEmail,
                password: signInPassword,
                name: customerName,
                company: customerCompany,
                phone: customerPhone,
                address: customerAddress,
                birthday: customerBirthday
            });
            // const { customerToken, profile, refreshToken } = response.data;

            console.log(response.data);
            // setProfile(profile);
            // setCustomerStatus(true);
            // Cookies.set("customerToken", customerToken);
            // Cookies.set("refreshToken", refreshToken);
            // dispatch(
            //     updateEchoUser({
            //         token: customerToken,
            //         profile: profile,
            //     })
            // );
        } catch (error) {
            console.error('Login failed', error);
            // Handle login failure (e.g., display error message)
            // setCustomerStatus(false)

        }
    }

    return (
        <Card
            elevation={0}
            variant="outlined"
            sx={{
                borderRadius: "20px"
            }}>
            <CardContent>
                <Grid container>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>

                        <Typography
                            variant="h2"
                            align="center">Customer Sign Up
                        </Typography>
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            type="email"
                            label="Email"
                            name="signInEmail"
                            value={signInEmail}
                            error={emailError ? true : false}
                            helperText={emailError}
                            onChange={handleSignInCustomer}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="normal"
                            type="password"
                            label="Password"
                            name="signInPassword"
                            value={signInPassword}
                            error={passwordError ? true : false}
                            helperText={passwordError}
                            onChange={handleSignInCustomer}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Customer Name (optional)"
                            value={customerName}
                            onChange={handleCustomerName}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Company (optional)"
                            value={customerCompany}
                            onChange={handleCustomerCompany}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone (optional)"
                            value={customerPhone}
                            onChange={handleCustomerPhone}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Address (optional)"
                            value={customerAddress}
                            onChange={handleCustomerAddress}
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Birthday (optional)"
                            value={customerBirthday}
                            onChange={handleCustomerBirthday}
                        />
                        <Button
                            style={{ marginTop: 40 }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleSignInSubmit}>
                            Create Customer
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default AddCustomerPanel;