import React, { useEffect, useState } from 'react';

// Material Components
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


// Axios API
import axiosInstance from "../../utils/axiosInstance";


function UpdateSystemForm() {
    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [systemList, GetSystems] = useState([]);

    const [company, setCompany] = React.useState(null);
    const [facilityName, setFacilityName] = React.useState(null);
    const [system, setSystem] = React.useState(null);
    const [room, setRoom] = React.useState("");
    const [zone, setZone] = React.useState("");
    const [device, setDevice] = React.useState("");

    const [systemName, setSystemName] = React.useState("");
    const [systemSerial, setSystemSerial] = React.useState("");
    const [systemRoom, setSystemRoom] = React.useState("");
    const [systemDevice, setSystemDevice] = React.useState("");
    const [systemZone, setSystemZone] = React.useState("");



    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];

    const [searchQuery, setSearchQuery] = useState('');


    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarOpenRemove, setSnackbarOpenRemove] = useState(false);

    const [parentId, setParentId] = useState("");

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])

    /**
        * Company Selection
        * @param {Event} e The event object
        * @param {String} value The name of the Company
        * @returns {Void}
    */
    const handleCompanyChange = (e, value) => {
        // console.log(e.target.value);
        setCompany(value);
        if (value !== "") {
            axiosInstance
                .get(`/facilities/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetFacilities(response.data);
                    console.log(response.data)
                    // setFacilityName(response.data[0].facility_name)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
        * Facility Update Submit
        * @param {Event} e The event object
        * @param {String} value The System Name
        * @returns {Void}
    */
    const handleFacilityChange = (e, value) => {
        setSystemName(value);


        if (value !== "" && company !== "") {

            axiosInstance.get(`/facilities/name/${company}/${value}`).then((response) => {
                console.log(response.data)

                console.log(response.data[0]._id)
                setParentId(response.data[0]._id)
            })
            axiosInstance
                .get(`/systems/${company}/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetSystems(response.data);
                    const system = response.data[0] ?? {};

                    // const facility = response.data[0] ?? {};

                    setFacilityName(system.facility_name ?? "");

                    setSystemName(system.system_name ?? "");
                    setSystemSerial(system.serial ?? "");
                    setSystemRoom(system.room_list ?? "");
                    setSystemZone(system.zone_list ?? "");
                    setSystemDevice(system.device_list ?? "");
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
        * System Change Name
        * @param {event} e The event object. 
        * @param {String} value The name of System. 
        * @returns {Void}
    */
    const handleSystemChange = (e, value) => {
        setSystem(value);
    }

    /**
        * System Change Room
        * @param {event} e The event object. 
        * @returns {Void}
    */
    const handleRoomChange = (e, value) => {
        setRoom(e.target.value);
    }

    /**
        * System Change Zone
        * @param {event} e The event object. 
        * @returns {Void}
    */
    const handleZoneChange = (e, value) => {
        setZone(e.target.value);
    }

    /**
        * System Change Device
        * @param {event} e The event object. 
        * @returns {Void}
    */
    const handleDeviceChange = (e, value) => {
        setDevice(e.target.value);
    }



    /**
        * System Change Serial
        * @param {Event} e The event object. 
        * @returns {Void}
    */
    const handleSystemSerialChange = (e, value) => {
        setSystemSerial(e.target.value);
    }

    /**
        * Update System Submit
        * @param {Event} e The event object.
        * @returns {Void}
    */
    const updateSystemSubmit = async (e) => {
        console.log(company, facilityName, system, room, zone, device, systemSerial);
        const roomList = room.split(",");
        const zoneList = zone.split(",");
        const deviceList = device.split(",");

        try {

            const response = await axiosInstance
                .post(`/systems`, {
                    company_name: company,
                    facility_name: facilityName,
                    system_name: system,
                    room_list: roomList,
                    zone_list: zoneList,
                    device_list: deviceList,
                    serial: systemSerial,
                    parent_id: parentId
                })
            setSnackbarMessage('Request successful! \n System: ' +
                response.data.system_name +
                "\nSerial: " +
                response.data.serial +
                "\nUPDATED");
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Error: ' + error.message);
            setSnackbarOpen(true);
        }

    }


    companyList?.map((company) => {
        companySearch.push({ label: company.company_name, companyObject: company })
    })

    facilityList?.map((facility) => {
        facilitySearch.push({ label: facility.facility_name, facilityObject: facility })
    })

    systemList?.map((facility) => {
        systemSearch.push({ label: facility.system_name, facilityObject: system })
    })

    const filteredCompanies = searchQuery ? companyList?.filter((word) => word.label.includes(searchQuery)) : companySearch
    const filteredFacilities = searchQuery ? facilityList?.filter((word) => word.label.includes(searchQuery)) : facilitySearch
    const filteredSystems = searchQuery ? systemList?.filter((word) => word.label.includes(searchQuery)) : systemSearch

    /**
        * Snackbar Close
        * @param {} 
        * @returns {Void}
    */
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarOpenRemove(false);
    };


    const handleParentId = () => {
        setParentId(parentId)
    }
    return (
        <Card
            elevation={0}
            variant="outlined"
            sx={{
                borderRadius: "10px"
            }}>
            <CardContent>
                <Grid
                    container
                    spacing={5}>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Typography variant='h2'>
                            Update System
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            Company
                        </Typography>
                        <Autocomplete
                            value={company}
                            onChange={handleCompanyChange}
                            disablePortal
                            id="company-search"
                            options={filteredCompanies.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Companies" />}
                        />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}>
                        <Typography variant='h3'>
                            Facility
                        </Typography>
                        <Autocomplete
                            value={facilityName}
                            onChange={handleFacilityChange}
                            disablePortal
                            id="facility-search"
                            options={filteredFacilities.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Facilities" />}
                        />
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            System
                        </Typography>
                        <Autocomplete
                            value={systemName}
                            onChange={handleSystemChange}
                            disablePortal
                            id="system-search"
                            options={filteredSystems.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Systems" />}
                        />
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleRoomChange}>
                            <Typography variant='h3'>
                                System Room
                            </Typography>
                            <TextField
                                id="room"
                                label="System Room"
                                variant="outlined"
                                value={systemRoom} />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleZoneChange}>
                            <Typography variant='h3'>
                                System Zone
                            </Typography>
                            <TextField
                                id="zone"
                                label="System Zone"
                                variant="outlined"
                                value={systemZone} />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleDeviceChange}>
                            <Typography variant='h3'>
                                System Device
                            </Typography>
                            <TextField
                                id="device"
                                label="System Device"
                                variant="outlined"
                                value={systemDevice} />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleSystemSerialChange}>
                            <Typography variant='h3'>
                                System Serial
                            </Typography>
                            <TextField
                                id="system-serial"
                                label="System Serial"
                                variant="outlined"
                                value={systemSerial} />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleParentId}>
                            <Typography variant='h3'>
                                Parent Facility Id
                            </Typography>
                            <Typography
                                id="parent-facility-id"
                                variant='h4'
                                color="#787878">
                                {parentId}
                            </Typography>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={updateSystemSubmit}>Update System</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}

                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{
                            width: '100%'
                        }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </CardActions>
        </Card>
    )
}

export default UpdateSystemForm;