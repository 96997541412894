import React, { useEffect, useState } from 'react';

// Material UI Components
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";

/**
    * InventoryDB Component
    * @returns {JSX.Element} The rendered InventoryDB Component.
*/
function InventoryDB() {
    const [loadingCompany, setLoadingCompany] = useState(true);
    const [loadingFacility, setLoadingFacility] = useState(true);
    const [loadingSystem, setLoadingSystem] = useState(true);
    const [loadingEquipment, setLoadingEquipment] = useState(true);


    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);
    const [facilityCount, GetFacilitiesCount] = useState([]);

    const [systemList, GetSystems] = useState([]);
    const [systemCount, GetSystemsCount] = useState([]);

    const [equipmentList, GetEquipments] = useState([]);

    const [company, setCompany] = React.useState("");
    const [facility, setFacility] = React.useState("");
    const [system, setSystem] = React.useState("");

    // const [room, setRoom] = React.useState("");
    // const [zone, setZone] = React.useState("");

    // const [facilityIdList, GetFacilitiesAccordion] = useState([]);



    useEffect(() => {

        axiosInstance
            .get(`/companies/active`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Fetched Successfully");
                // console.log(companyList)
                setLoadingCompany(false);
            })
            .catch(error => {
                console.log(error);
                setLoadingCompany(true);
            });
        if (company !== "") {
            fetchCompanyData(); // Function to fetch data from API based on the selected company
        }
        if (facility !== "") {
            fetchFacilityData(); // Function to fetch data from API based on the selected company
        }
        if (system !== "") {
            fetchSystemData(); // Function to fetch data from API based on the selected company
        }

    }, [company, facility, system, GetCompanies])

    /**
        * Fetches company data from the API based on the selected company.
        * @param {}
        * @returns {Void} 
    */
    const fetchCompanyData = async () => {
        await axiosInstance
            .get(`/facilities/${company}`).then(response => {
                // console.log(response.data);
                GetFacilities(response.data);
                GetFacilitiesCount(response.data.length);
                if (response.data.length > 0) {
                    console.log("User Fetched and Facilities Fetched Successfully");
                    // console.log(companyList)
                    setLoadingFacility(false);
                }
                else {
                    console.log("Missing facilities");
                }

            })
            .catch(error => {
                console.log(error);
                setLoadingFacility(true);
            });
    }

    /**
        * Fetches facility data from the API based on the selected facility.
        * @param {}
        * @returns {Void} 
    */
    const fetchFacilityData = async () => {

        await axiosInstance
            .get(`/systems/${company}/${facility}`).then(response => {
                // console.log(response.data);
                GetSystems(response.data);
                GetSystemsCount(response.data.length);
                if (response.data.length > 0) {
                    console.log("User Fetched and Systems Fetched Successfully");
                    // console.log(companyList)
                    setLoadingSystem(false);
                }
                else {
                    console.log("Missing systems");
                }

            })
            .catch(error => {
                console.log(error);
                setLoadingSystem(true);
            });

    }

    /**
        * Fetches system data from the API based on the selected system.
        * @param {}
        * @returns {Void} 
    */
    const fetchSystemData = async () => {
        console.log(company);
        console.log(facility);
        console.log(system);

        await axiosInstance
            .get(`/equipments/${company}/${facility}/${system}`).then(response => {
                console.log(response.data);
                GetEquipments(response.data);
                if (response.data.length > 0) {
                    console.log("User Fetched and Parts Successfully");
                    // console.log(companyList)
                    setLoadingEquipment(false);
                }
                else {
                    console.log("Missing systems");
                    setLoadingEquipment(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    /**
        * Radio button handler for the company radio button.
        * @param {Event} event The event object.
        * @returns {Void} 
    */
    const handleCompanyRadio = (event) => {
        setCompany(event.target.value);
        console.log(`/facilities/${company}`)
        setLoadingFacility(true);
        setLoadingSystem(true);
    }

    /**
        * Radio button handler for the facility radio button.
        * @param {Event} event The event object.
        * @returns {Void} 
    */
    const handleFacilityRadio = (event) => {
        setFacility(event.target.value);
        console.log(`/systems/${company}/${facility}`)
        setLoadingFacility(true);
        setLoadingSystem(true);
    }

    /**
        * Radio button handler for the System radio button.
        * @param {Event} event The event object.
        * @returns {Void} 
    */
    const handleSystemRadio = (event) => {
        setSystem(event.target.value);
        // console.log(`/systems/${company}/${facility}/${system}/`)
    }

    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Inventory DB
                        </Typography>
                    </CardContent>

                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                {/* <Paper >
                    <Grid container padding={5}>
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                    </Grid>
                </Paper> */}

                <Paper>
                    <Grid container>
                        <Grid
                            padding={1}
                            size={{
                                md: 4,
                                xs: 6
                            }}>
                            <Autocomplete
                                multiple
                                id="company-filter"
                                options={companyList}
                                style={{ width: "100%" }}
                                getOptionLabel={(option) => option.company_name}

                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.company_name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Company Filter" placeholder="Company Filter" />
                                )}
                            />
                            <Card
                                sx={{
                                    height: "25vh",
                                    overflowY:
                                        "auto"
                                }}>
                                <CardContent>
                                    {/* <Box>
                                        {loadingCompany && <CircularProgress aria-label="Loading..." />}
                                    </Box> */}
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="company-radio-buttons-group"
                                            defaultValue=""
                                            value={company}
                                            name="company-radio-buttons-group"
                                            onChange={handleCompanyRadio}
                                        >

                                            {!loadingCompany ? (companyList?.map((company, index) => {
                                                // console.log(company)
                                                return (
                                                    <FormControlLabel
                                                        key={company + index}
                                                        value={company.company_name}
                                                        control={<Radio />}
                                                        label={company.company_name + " - " + company?.serial} />
                                                )
                                            })) : (
                                                <Stack spacing={5}>
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                </Stack>)}
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            size={{
                                md: 4,
                                xs: 6
                            }}
                            padding={1}>
                            <Autocomplete
                                multiple
                                id="facility-filter"
                                options={facilityList}
                                style={{ width: "100%" }}
                                getOptionLabel={(option) => option.facility_name}

                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.facility_name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Facility Filter" placeholder="Facility Filter" />
                                )}
                            />
                            <Card sx={{ "height": "25vh", overflowY: "auto" }}>
                                <CardContent>
                                    {/* <Box>
                                        {loadingFacility && <CircularProgress aria-label="Loading..." />}
                                    </Box> */}
                                    <FormControl>

                                        <RadioGroup
                                            aria-labelledby="facility-radio-buttons-group"
                                            defaultValue=""
                                            value={facility}
                                            name="facility-radio-buttons-group"
                                            onChange={handleFacilityRadio}
                                        >
                                            {!loadingFacility ? (facilityList?.map((facility, index) => {
                                                // console.log(company)
                                                return (
                                                    <FormControlLabel key={facility + index} value={facility.facility_name} control={<Radio />} label={facility.facility_name + " - " + facility?.serial} />
                                                )
                                            })) : (
                                                <Stack spacing={5}>
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                </Stack>)}
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            size={{
                                md: 4,
                                xs: 6
                            }}
                            padding={1}>
                            <Autocomplete
                                multiple
                                id="system-filter"
                                options={systemList}
                                style={{ width: "100%" }}
                                getOptionLabel={(option) => option.system_name}

                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.system_name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="System Filter" placeholder="System Filter" />
                                )}
                            />
                            <Card sx={{ "height": "25vh", overflowY: "auto" }}>
                                <CardContent>
                                    {/* <Box>
                                        {loadingSystem && <CircularProgress aria-label="Loading..." />}
                                    </Box> */}
                                    <FormControl>

                                        <RadioGroup
                                            aria-labelledby="system-radio-buttons-group"
                                            defaultValue=""
                                            name="system"
                                            value={system}
                                            onChange={handleSystemRadio}
                                        >
                                            {!loadingSystem ? (systemList?.map((system, index) => {
                                                // console.log(company)
                                                return (
                                                    <FormControlLabel key={system + index} value={system.system_name} control={<Radio />} label={system.system_name} />
                                                )
                                            })) : (
                                                <Stack spacing={5}>
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                    <Skeleton variant="rounded" animation="wave" height={20} width={"25vw"} />
                                                </Stack>)}
                                        </RadioGroup>
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            size={{
                                md: 12,
                                xs: 12
                            }}
                            padding={1}>
                            <Card sx={{ "height": "40vh", overflowY: "auto" }}>
                                <CardContent>
                                    <Grid container>
                                        {!loadingEquipment ? (equipmentList?.map((equipment, index) => {
                                            console.log(equipment)
                                            return (
                                                <Grid size={{
                                                    md: 4,
                                                    xs: 12
                                                }}>
                                                    <Card>
                                                        <Typography variant="h3">
                                                            {equipment?.equipment_name}
                                                        </Typography>
                                                        {equipment?.parts?.map((part) => {
                                                            return (
                                                                <Button variant="h6">
                                                                    {part.part_name}
                                                                </Button>
                                                            )
                                                        }
                                                        )}
                                                    </Card>
                                                </Grid>)
                                        })) : (
                                            <Stack spacing={5}>
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />
                                                <Skeleton variant="rounded" animation="wave" height={20} width={"80vw"} />

                                            </Stack>)}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default InventoryDB;