import React, { useEffect, useState } from 'react';

// Material Components
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

// Material UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Axios API
import axiosInstance from "../utils/axiosInstance";

// Tabs
import { SystemTabPanel, a11ySystemProps } from '../components/system/SystemTabPanel';
import AddSystemForm from '../components/system/AddSystemForm';
import UpdateSystemForm from '../components/system/UpdateSystemForm';
import RemoveSystemForm from '../components/system/RemoveSystemForm';

/**
    * System Component
    * @param {Number} activeCrud The active CRUD operation
    * @returns {JSX.Element} The rendered Facility Component.
*/
function System({ activeCrud }) {
    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [systemList, GetSystems] = useState([]);

    const [system, setSystem] = React.useState(null);



    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];


    const [systemTab, setSystemTab] = React.useState(0);


    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])



    companyList?.map((company) => {
        companySearch.push({ label: company.company_name, companyObject: company })
    })

    facilityList?.map((facility) => {
        facilitySearch.push({ label: facility.facility_name, facilityObject: facility })
    })

    systemList?.map((facility) => {
        systemSearch.push({ label: facility.system_name, facilityObject: system })
    })

    /**
        * System CRUD
        * @param {Event} event The event object
        * @param {Number} newValue The new value of the system tab
        * @returns {Void}
    */
    const handleSystemCrud = (event, newValue) => {
        setSystemTab(newValue);
    };



    return (
        <Container>
            {activeCrud === 2 && <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                    }}>
                    <Tabs
                        variant="fullWidth"
                        value={systemTab}
                        onChange={handleSystemCrud}
                        aria-label="system tabs">
                        <Tab
                            label="ADD System"
                            icon={<AddCircleOutlineIcon />} {...a11ySystemProps(0)} />
                        <Tab
                            label="UPDATE System"
                            icon={<EditIcon />} {...a11ySystemProps(1)} />
                        <Tab
                            label="REMOVE System"
                            sx={{
                                color: 'primary', // Text color of the tab
                                '&.Mui-selected': {
                                    color: 'error', // Text color of the tab when selected
                                },
                                '& .MuiTab-indicator': {
                                    backgroundColor: 'error', // Indicator color of the tab
                                },
                            }}
                            icon={<RemoveCircleOutlineIcon />} {...a11ySystemProps(2)} />

                    </Tabs>
                </Box>
                <SystemTabPanel
                    value={systemTab}
                    index={0}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <AddSystemForm />
                    )}
                </SystemTabPanel>
                <SystemTabPanel
                    value={systemTab}
                    index={1}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <UpdateSystemForm />
                    )}
                </SystemTabPanel>
                <SystemTabPanel
                    value={systemTab}
                    index={2}>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <RemoveSystemForm />
                    )}
                </SystemTabPanel>
            </Box>}
        </Container>
    )
}

export default System;