import React, { useEffect } from 'react';

// Material UI Components
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';


import employeeData from "./employeeDirectory.json"


/**
    * Department Component
    * @returns {JSX.Element} The rendered Department Component.
*/
function Department() {
    const employees = employeeData.employeeDirectory;

    useEffect(() => {

    }, [])
    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Department
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography variant="h2">
                                HR
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'HR') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"hr" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }

                                </TableBody>
                            </TableContainer>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography variant="h2">
                                Mechanical
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Mechanical') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"mechanical" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>

                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h2">
                                Electrical
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Electrical') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"electrical" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h2">
                                Controls
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Controls') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"controls" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h2">
                                Construction
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Construction') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"construction" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>

                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h2">
                                Finance
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Finance') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"finance" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3-content"
                            id="panel3-header"
                        >
                            <Typography variant="h2">
                                Office
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableContainer component={Paper}>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Head</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Employees</TableCell>
                                        <TableCell>Address</TableCell>
                                        <TableCell>Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        employees
                                            ?.filter(employee => employee.department === 'Office') // Filter employees by department
                                            .map((employee, index) => {
                                                return (
                                                    <TableRow key={"office" + index}>
                                                        <TableCell>{employee.department}</TableCell>
                                                        <TableCell>{employee.title}</TableCell>
                                                        <TableCell>{employee.contact_name}</TableCell>
                                                        <TableCell>{employee.mobile_phone}</TableCell>
                                                        <TableCell>{employee.office_number}</TableCell>
                                                        <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', whiteSpace: 'normal' }}>{employee.email}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </TableContainer>
                        </AccordionDetails>
                        <AccordionActions>
                            <Button>Edit</Button>
                            <Button>Delete</Button>
                        </AccordionActions>
                    </Accordion>

                </Container >
            </Grid >
        </Grid >
    )
}

export default Department;