import * as React from 'react';

// Material Components
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Material UI Icons
import MenuIcon from '@mui/icons-material/Menu';

// React Router
import { Link, Outlet } from 'react-router-dom';

// Images
import cstLogo from '../images/CST.jpg';

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

/**
    * Navbar Component
    * @param {}
    * @returns {JSX.Element} The rendered Navbar Component.
*/
function Navbar() {

    const [value, setValue] = React.useState(0);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    /**
        * Change Public App Bar Link
        * @param {Event} event The event object.
        * @param {Number} newValue Tab value.
        * @returns {Void}
    */
    const handleChange = (event, newValue) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' && samePageLinkNavigation(event))
        ) {
            setValue(newValue);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    /**
        * Mobile Open Nav Menu
        * @param {Event} event The event object.
        * @returns {}
    */
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    /**
        * Close Nav Menu
        * @returns {Void}
    */
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    return (
        <div>
            <AppBar
                position="sticky"
                elevation={0}
                sx={{
                    background: "#fff",
                    color: "#253351",
                    WebkitFontSmoothing: 'antialiased',

                }}>
                <Box
                    sx={{
                        flexGrow: 1
                    }}>

                    <Container maxWidth={"xl"}>
                        <Toolbar disableGutters>
                            {/* <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                        </IconButton> */}
                            <Box sx={{
                                flexGrow: 1,
                                display: {
                                    xs: 'flex',
                                    md: 'none'
                                }
                            }}>
                                <IconButton
                                    size="large"
                                    aria-label="Dropdown Menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            md: 'none'
                                        },
                                    }}
                                >
                                    <MenuItem
                                        key={0}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/"
                                            color="inherit"
                                            label="Home" >
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={1}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/service"
                                            color="inherit"
                                            sx={{ textTransform: 'lowercase' }}
                                            label="Service">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={2}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/controls"
                                            color="inherit"
                                            label="Controls">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={3}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/new-construction"
                                            color="inherit"
                                            label="New Construction">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={4}
                                        onClick={handleCloseNavMenu}>
                                        <Tab component={Link}
                                            to="/rmp"
                                            color="inherit"
                                            label="RMP">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={5}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/training"
                                            color="inherit"
                                            label="Training">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={6}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/about"
                                            color="inherit"
                                            label="About Us">
                                        </Tab>
                                    </MenuItem>
                                    <MenuItem
                                        key={7}
                                        onClick={handleCloseNavMenu}>
                                        <Tab
                                            component={Link}
                                            to="/contact"
                                            color="inherit"
                                            label="Contact">
                                        </Tab>
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: {
                                        xs: 'none',
                                        md: 'flex'
                                    }
                                }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="inherit"
                                    indicatorColor='primary'
                                    aria-label="nav tabs "
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            height: '6px', // Adjust the thickness here
                                            borderRadius: "2px",
                                            color: "red",
                                            background: "#6099f0",
                                            top: 0,
                                            transition: 'all .69s ease-in-out', // Smooth transition
                                        },

                                    }}
                                    role="navigation">
                                    <Tab
                                        component={Link}
                                        to="/"
                                        color="inherit"
                                        icon={<img src={cstLogo} alt="logo" width="120" />}>
                                    </Tab>
                                    {/* <Button component={Link} to="/" color="inherit">Home</Button> */}
                                    <Tab
                                        component={Link}
                                        to="/service"
                                        color="inherit"
                                        label="Service">
                                    </Tab>
                                    <Tab
                                        component={Link}
                                        to="/controls"
                                        color="inherit"
                                        label="Controls">
                                    </Tab>
                                    <Tab
                                        component={Link}
                                        to="/new-construction"
                                        color="inherit"
                                        label="New Construction">
                                    </Tab>
                                    <Tab component={Link}
                                        to="/rmp"
                                        color="inherit"
                                        label="RMP">
                                    </Tab>
                                    <Tab
                                        component={Link}
                                        to="/training"
                                        color="inherit"
                                        label="Training">
                                    </Tab>
                                    <Tab
                                        component={Link}
                                        to="/about"
                                        color="inherit"
                                        label="About Us">
                                    </Tab>
                                    <Tab
                                        component={Link}
                                        to="/contact"
                                        color="inherit"
                                        label="Contact">
                                    </Tab>
                                </Tabs>
                            </Box>
                            {/* <Button component={Link} to="/auth/builder" color="inherit">Builder</Button>
                            <Button component={Link} to="/auth/inventory" color="inherit">Inventory</Button>
                            <Button component={Link} to="/auth/barcodes" color="inherit">Barcodes</Button> */}
                            <Button
                                size='large'
                                variant="filled"
                                sx={{
                                    marginLeft: "auto",
                                    marginRight: "2rem",
                                    padding: '7px 65px',
                                    borderRadius: "12px",
                                    color: "#fff",
                                    background: "#1e6ce1",
                                    '&:hover': {
                                        color: "#253351", // Ensure font color stays white on hover
                                    },
                                }}
                                component={Link}
                                to="/signin"
                                color="inherit">Login</Button>
                        </Toolbar>
                    </Container>
                </Box>

            </AppBar>
            <Outlet />
            <footer
                style={{
                    marginTop: "100px",
                    background: "#0b111a",
                    height: '300px',
                    padding: "120px",
                    color: 'white'
                }}>
                <Container>
                    <Box
                        sx={{
                            flexGrow: 1,
                            textAlign: "center"
                        }}>
                        <Stack
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={{
                                xs: 1,
                                sm: 2,
                                md: 4
                            }}>
                            <Grid
                                container
                                padding={4}
                                spacing={2}
                                minHeight={100} >
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>Home
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/service" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            Service
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/controls" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            Controls
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/new-construction" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            New Construction
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/rmp" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            RMP
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/training" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            Training
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/about" color="inherit">
                                        <Typography

                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            About Us
                                        </Typography>

                                    </Link>
                                </Grid>
                                <Grid
                                    size={{
                                        md: 2,
                                        xs: 12
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Link to="/contact" color="inherit">
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                fontFamily: 'Arimo',
                                                color: 'white'
                                            }}>
                                            Contact
                                        </Typography>

                                    </Link>
                                </Grid>

                            </Grid>
                        </Stack>
                        <Box
                            sx={{
                                padding: "50px",
                                textAlign: "center"
                            }}>

                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                }} display="flex"
                                justifyContent="center"
                                alignItems="center">© 2024 Cold Storage Technologies Inc All Rights Reserved</Typography>
                        </Box>
                    </Box>
                </Container>
            </footer>
        </div>
    );
}
export default Navbar;