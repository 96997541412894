import React, { useEffect, useState } from 'react';

// Material Components
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


// Axios API
import axiosInstance from "../../utils/axiosInstance";


function RemoveSystemForm() {
    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [systemList, GetSystems] = useState([]);

    const [company, setCompany] = React.useState(null);
    const [facilityName, setFacilityName] = React.useState(null);
    const [system, setSystem] = React.useState(null);


    const [systemName, setSystemName] = React.useState("");
    const [systemSerial, setSystemSerial] = React.useState("");
    const [systemRoom, setSystemRoom] = React.useState("");
    const [systemDevice, setSystemDevice] = React.useState("");
    const [systemZone, setSystemZone] = React.useState("");



    const companySearch = [];
    const facilitySearch = [];
    const systemSearch = [];

    const [searchQuery, setSearchQuery] = useState('');


    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarMessageRemove, setSnackbarMessageRemove] = useState('');
    const [snackbarOpenRemove, setSnackbarOpenRemove] = useState(false);

    const [parentId, setParentId] = useState("");

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])

    /**
        * Company Selection
        * @param {Event} e The event object
        * @param {String} value The name of the Company
        * @returns {Void}
    */
    const handleCompanyChange = (e, value) => {
        // console.log(e.target.value);
        setCompany(value);
        if (value !== "") {
            axiosInstance
                .get(`/facilities/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetFacilities(response.data);
                    console.log(response.data)
                    // setFacilityName(response.data[0].facility_name)
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
        * Facility Update Submit
        * @param {Event} e The event object
        * @param {String} value The System Name
        * @returns {Void}
    */
    const handleFacilityChange = (e, value) => {
        setSystemName(value);


        if (value !== "" && company !== "") {

            axiosInstance.get(`/facilities/name/${company}/${value}`).then((response) => {
                console.log(response.data)

                console.log(response.data[0]._id)
                setParentId(response.data[0]._id)
            })
            axiosInstance
                .get(`/systems/${company}/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetSystems(response.data);
                    const system = response.data[0] ?? {};

                    // const facility = response.data[0] ?? {};

                    setFacilityName(system.facility_name ?? "");

                    setSystemName(system.system_name ?? "");
                    setSystemSerial(system.serial ?? "");
                    setSystemRoom(system.room_list ?? "");
                    setSystemZone(system.zone_list ?? "");
                    setSystemDevice(system.device_list ?? "");
                    console.log(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    /**
        * System Change Name
        * @param {event} e The event object. 
        * @param {String} value The name of System. 
        * @returns {Void}
    */
    const handleSystemChange = (e, value) => {
        setSystem(value);
    }


    companyList?.map((company) => {
        companySearch.push({ label: company.company_name, companyObject: company })
    })

    facilityList?.map((facility) => {
        facilitySearch.push({ label: facility.facility_name, facilityObject: facility })
    })

    systemList?.map((facility) => {
        systemSearch.push({ label: facility.system_name, facilityObject: system })
    })

    const filteredCompanies = searchQuery ? companyList?.filter((word) => word.label.includes(searchQuery)) : companySearch
    const filteredFacilities = searchQuery ? facilityList?.filter((word) => word.label.includes(searchQuery)) : facilitySearch
    const filteredSystems = searchQuery ? systemList?.filter((word) => word.label.includes(searchQuery)) : systemSearch


    /**
        * Snackbar Close
        * @param {} 
        * @returns {Void}
    */
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarOpenRemove(false);
    };

    /**
        * Remove System with System API
        * @param {} 
        * @returns {Void}
    */
    const removeSystem = () => {
        try {
            axiosInstance
                .delete(`/systems`)
        } catch (error) {
            setSnackbarMessageRemove('Error: ' + error.message);
            setSnackbarOpenRemove(true);
        }

    }

    return (
        <Card
            elevation={0}
            variant="outlined"
            sx={{
                borderRadius: "10px"
            }}>
            <CardContent>
                <Grid
                    container
                    spacing={5}>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Typography variant='h2'>
                            Remove System
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            Company
                        </Typography>
                        <Autocomplete
                            value={company}
                            onChange={handleCompanyChange}
                            disablePortal
                            id="company-search"
                            options={filteredCompanies.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Companies" />}
                        />
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            Facility
                        </Typography>
                        <Autocomplete
                            value={facilityName}
                            onChange={handleFacilityChange}
                            disablePortal
                            id="facility-search"
                            options={filteredFacilities.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Facilities" />}
                        />
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            System
                        </Typography>
                        <Autocomplete
                            value={system}
                            onChange={handleSystemChange}
                            disablePortal
                            id="system-search"
                            options={filteredSystems.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Systems" />}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={removeSystem}>REMOVE System</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={snackbarOpenRemove}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {snackbarMessageRemove}
                    </Alert>
                </Snackbar>
            </CardActions>
        </Card>
    )
}

export default RemoveSystemForm;