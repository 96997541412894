import React, { useEffect, useState } from 'react';

// Material UI Components
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Material UI Icons
import Snackbar from '@mui/material/Snackbar';

// Axios API
import axiosInstance from "../../utils/axiosInstance";

function RemoveCompanyForm() {
    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [company, setCompany] = React.useState(null);


    const [companySerial, setCompanySerial] = React.useState("");
    const [companyStatus, setCompanyStatus] = React.useState("ACTIVE");
    const [companyWebsite, setCompanyWebsite] = React.useState("");
    const [companyDescription, setCompanyDescription] = React.useState("");

    const [selectCompany, GetSelectCompany] = React.useState({});

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarMessageRemove, setSnackbarMessageRemove] = useState('');
    const [snackbarOpenRemove, setSnackbarOpenRemove] = useState(false);

    const companySearch = [];
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])

    /**
        * Updates the company and facility information when a company name is selected.
        * 
        * This function is called when the user selects a company from the Autocomplete 
        * dropdown. It fetches the company details from the `/companies/name/:companyName` 
        * API endpoint and updates the corresponding state variables. It also fetches 
        * the facilities associated with the selected company from the `/facilities/:companyName` 
        * API endpoint and updates the `facilityList` state.
        * 
        * @param {Event} e The event object.
        * @param {String} value The value of the company name selected from the Autocomplete.
        * @returns {Void}
    */
    const handleCompanyChange = (e, value) => {
        // console.log(e.target.value);
        setCompany(value);
        if (value !== "") {
            axiosInstance
                .get(`/companies/name/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    // GetFacilities(response.data);
                    GetSelectCompany(response.data);
                    const company = response.data[0] ?? {};

                    setCompanyStatus(company.status ?? "");
                    setCompanySerial(company.serial ?? "");
                    setCompanyWebsite(company.website ?? "");
                    setCompanyDescription(company.description ?? "");
                    console.log(response.data)
                })
                .catch((error) => {
                    console.error(error);
                });
            axiosInstance
                .get(`/facilities/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetFacilities(response.data);
                    console.log(response.data)
                })
                .catch((error) => {
                    console.error(error);
                });

            console.log("PICTURE")


            axiosInstance('/companies/images/AB company.png_1724189778331').then(response => {
                console.log(response.data)
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    companyList?.map((company) => {
        companySearch.push(
            {
                label: company.company_name,
                companyObject: company
            })
    })



    const filteredCompanies = searchQuery ? companyList?.filter((word) => word.label.includes(searchQuery)) : companySearch



    /**
        * Closes the snackbar.
        * 
        * This function is called when the user clicks the close button or outside the 
        * Snackbar. It updates the state variables `snackbarOpen` and `snackbarOpenRemove` 
        * to `false`, effectively hiding the Snackbar.
        * 
        * @returns {Void}
    */
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarOpenRemove(false);
    };

    /**
        * Removes a company from the database.
        * 
        * This function sends a DELETE request to the `/companies` API endpoint to remove 
        * the currently selected company from the database. It handles potential errors 
        * during the deletion process and displays an error message in a Snackbar if 
        * an error occurs.
        * 
        * @returns {Void}
    */
    const removeCompany = () => {
        try {
            axiosInstance
                .delete(`/companies`)
        } catch (error) {
            setSnackbarMessageRemove('Error: ' + error.message);
            setSnackbarOpenRemove(true);
        }
    }


    return (
        <Card elevation={0} variant="outlined" sx={{ borderRadius: "10px" }}>
            <CardContent>
                <Grid
                    container
                    spacing={5}>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Typography variant='h2'>
                            Remove Company
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            Company
                        </Typography>
                        <Autocomplete
                            value={company}
                            onChange={handleCompanyChange}
                            disablePortal
                            id="company-search"
                            options={filteredCompanies.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Companies" />}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={removeCompany}>REMOVE Company</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={snackbarOpenRemove}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {snackbarMessageRemove}
                    </Alert>
                </Snackbar>
            </CardActions>
        </Card>
    )
}

export default RemoveCompanyForm;