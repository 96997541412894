import React, { useRef } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// Material UI Icons
import Drawer from '@mui/material/Drawer';

// Toolbar
import ToolPanelRight from '../../../components/ToolPanelRight';
import VisualToolbar from '../../../components/VisualToolbar';

/**
    * Visual Component
    * @returns {JSX.Element} The rendered Visual Component.
*/
function Visual({ canvasRef }) {

    canvasRef = useRef(null);

    return (
        <Grid
            container
            spacing={2}
            padding={2} >
            <Grid
                size={{
                    md: 8,
                    xs: 8
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "99vw"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Visual
                        </Typography>
                    </CardContent>
                    <CardActions >
                        <VisualToolbar canvasRef={canvasRef} />
                    </CardActions>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Drawer
                    sx={{
                        width: "20vw",
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: "20vw",
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="right"
                >
                    <Box
                        style={{
                            marginTop: "130px",
                            height: "100vh"
                        }}>
                        <ToolPanelRight canvasRef={canvasRef} />
                    </Box>
                </Drawer>
                <canvas
                    data-testid="drawing-canvas"
                    ref={canvasRef}
                    width={"1470rem"}
                    height={"755rem"}
                    style={{
                        border: '1px solid #dedede',
                        borderRadius: "20px"
                    }} />
            </Grid>
        </Grid>
    )
}

export default Visual;
