
// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import derrekImage from '../../images/employees/DerrekMiller.jfif';
import edImage from '../../images/employees/EdQuintana.jfif';
import tylerImage from '../../images/employees/TylerSestini.jfif';
import cstFacility from '../../images/cst_home/cst_facility.jpg';


const itemData = [
    {
        img: derrekImage,
        name: 'Derrek Miller',
        title: 'Controls Engineer'
    },
    {
        img: edImage,
        name: 'Ed Quintana',
        title: 'Controller'
    },
    {
        img: tylerImage,
        name: 'Tyler Sestini',
        title: 'Sales Engineer'

    },

];

function ContactUs() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    Contact Us
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    padding={16}
                    spacing={5}>

                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardContent>
                                <Typography variant='h2' sx={{ fontFamily: "IBM Plex Sans" }}>Cold Storage Technologies Headquarters
                                </Typography>
                                <Typography variant='h3' sx={{ fontFamily: "IBM Plex Sans" }}>4691 N Blythe Ave Ste A, Fresno, CA 93722
                                </Typography>
                                <Link href={`mailto:Contact@Coldstoragetech.com`} sx={{ fontFamily: "IBM Plex Sans" }}>
                                    <Typography variant="h4" sx={{ fontFamily: "IBM Plex Sans" }}>
                                        Contact@Coldstoragetech.com
                                    </Typography>
                                </Link>
                                <Typography variant='h4' sx={{ fontFamily: "IBM Plex Sans" }}>
                                    Phone:
                                    <Link role="link" href={`tel:+15592750110`} sx={{ fontFamily: "IBM Plex Sans" }}>
                                        (559) 275-0110
                                    </Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Box
                            style={{
                                width: '100%',   // Full width of the page
                                height: '400px', // Fixed height for the banner (adjust as needed)
                                overflow: 'hidden' // Ensures that the content that exceeds the height will be hidden (cropping effect)
                            }}
                        >
                            <img
                                src={cstFacility}
                                style={{
                                    width: '100%',
                                    height: '100%', // Ensures the image height fills the container
                                    objectFit: 'cover', // Crops the image to maintain aspect ratio while covering the container
                                }}
                                alt="Banner"
                            />
                        </Box>
                    </Grid>

                </Grid>

            </Container>

            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    {/* <motion.div
                    initial={{
                        scale: .55
                    }}
                    animate={{
                        scale: isVisible ? 1 : .55
                    }}
                    transition={{
                        duration: 1.3
                    }}
                > */}
                    <Box
                        sx={{
                            background: "linear-gradient(353deg, #070f2e, #010514)",
                            color: " white"
                        }}>
                        <Container>
                            <ImageList
                                sx={{
                                    width: "100%",
                                    padding: "150px",
                                    height: 'auto', // Adjusts to the content's height 
                                    // gap: 50, // Adds space between items
                                }}
                                cols={4}
                                rowHeight={400} // A smaller row height for business contact photos
                            >
                                {itemData.map((item) => (
                                    <ImageListItem
                                        key={item.img}
                                        sx={{
                                            borderRadius: "0px", // Keep corners square
                                            overflow: "hidden", // Ensure the rounded corners work with images
                                            position: "relative", // Allows positioning the overlay
                                        }}
                                    >
                                        <img
                                            srcSet={`${item.img}`}
                                            src={item.img}
                                            alt={item.title}
                                            loading="lazy"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover', // Ensures the image covers the entire area without distortion
                                            }}
                                        />
                                        <ImageListItemBar
                                            title={
                                                <Typography variant="h2" component="div"
                                                    sx={{
                                                        fontFamily: "Fira Sans",
                                                        color: '#375fd7',
                                                        fontWeight: 600,
                                                    }}>
                                                    {item.name}
                                                </Typography>
                                            }
                                            subtitle={
                                                <Typography variant="h5" component="div"
                                                    sx={{
                                                        fontFamily: "Fira Sans",
                                                        color: '#375fd7',
                                                        fontWeight: 600,
                                                    }}>
                                                    {item.title}
                                                </Typography>
                                            }
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                background: 'rgba(250, 250, 250, .825)', // Dark background for text readability
                                                padding: '10px',

                                            }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                        </Container>
                    </Box>
                    {/* </motion.div> */}
                </Grid>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>

                </Grid>

                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ContactUs;