// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// Images
import epaImage from '../../images/epa_logo.png';
import iiarImage from '../../images/iiar_logo.gif';

function RMP() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    RMP
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>
                    <Grid
                        size={{
                            md: 8,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardContent>
                                <Typography
                                    variant='h2'
                                    style={{
                                        fontWeight: 600
                                    }}>
                                    We provide complete RMP/PSM/CalARP plans and support.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={iiarImage}
                                sx={{
                                    padding: "1em 1em 0 1em",
                                    objectFit: "contain"
                                }}
                                alt="iiar">
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card elevation={0}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={epaImage}
                                sx={{
                                    padding: "1em 1em 0 1em",
                                    objectFit: "contain"
                                }}
                                alt="epa">
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box>
                        <Container>
                            <Box sx={{ padding: "100px" }}>
                                <Divider></Divider>
                            </Box>

                            <Typography
                                variant='h2'
                                sx={{
                                    fontFamily: 'IBM Plex Sans',
                                    fontWeight: 600,
                                    textAlign: "center",
                                    padding: "100px"
                                }}>
                                <span className='gradientGlow'>
                                    Our approach is to provide the specific regulations and references in all sections so facility employees can understand and maintain their programs.
                                </span>
                            </Typography>
                        </Container>
                        <Box sx={{ padding: "100px" }}>
                            <Divider></Divider>
                        </Box>
                    </Box>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Box sx={{
                            background: "linear-gradient(353deg, #283f99, #2377d5)",
                            color: " white"
                        }}>
                            <Container>
                                <Typography
                                    variant='h3'
                                    sx={{
                                        fontFamily: 'IBM Plex Sans',
                                        fontWeight: 600,
                                        textAlign: "center",
                                        padding: "100px"
                                    }}>
                                    Since we're refrigeration contractors we can also provide necessary drawings and Standard Operating Procedures so your plan looks professional to reviewers.                        </Typography>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default RMP;