import React, { useEffect } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Typography from '@mui/material/Typography';

function createWorkOrder(id, description, assign, status) {
    return { id, description, assign, status };
}
function createAsset(id, name, location, condition) {
    return { id, name, location, condition };
}

function createInventory(id, name, quantity, location) {
    return { id, name, quantity, location };
}

const rowsWorkOrder = [
    createWorkOrder(1, 'Repair HVAC', "John", "PENDING"),
    createWorkOrder(2, 'Replace Panel', "John", "CLOSED"),
    createWorkOrder(3, 'Repair HVAC', "John", "OPEN"),
    createWorkOrder(4, 'Repair HVAC', "John", "PENDING"),
];
const rowsAsset = [
    createAsset(1, 'Compressors', "Busseto", "Good"),
    createAsset(2, 'Condensers', "Busseto", "Good"),
    createAsset(3, 'Recievers', "Busseto", "Good"),
    createAsset(4, 'Evaporators', "Busseto", "Good"),
];

const rowsInventory = [
    createInventory(1, 'Compressors', 16, "Busseto"),
    createInventory(2, 'Condensers', 8, "Busseto"),
    createInventory(3, 'Recievers', 2, "Busseto"),
    createInventory(4, 'Evaporators', 8, "Busseto"),
];

/**
    * WorkOrders Component
    * @returns {JSX.Element} The rendered WorkOrders Component.
*/
function WorkOrders() {

    useEffect(() => {

    }, [])
    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Work Orders
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Typography variant="h1">
                        Work Orders List
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Description</TableCell>
                                    <TableCell align="right">Assign</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsWorkOrder.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.description}</TableCell>
                                        <TableCell align="right">{row.assign}</TableCell>
                                        <TableCell align="right">{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="h1">
                        Assets
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Location</TableCell>
                                    <TableCell align="right">Condition</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsAsset.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.location}</TableCell>
                                        <TableCell align="right">{row.condition}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="h1">
                        Inventory
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Location</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsInventory.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.quantity}</TableCell>
                                        <TableCell align="right">{row.location}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="h1">
                        Reports
                    </Typography>

                </Container >
            </Grid >
        </Grid >
    )
}

export default WorkOrders;