import React, { useEffect, useState } from 'react';

// Material UI Components
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import CameraAltIcon from '@mui/icons-material/CameraAlt';


// Axios API
import axiosInstance from "../utils/axiosInstance";


// React Redux
import { useSelector } from "react-redux";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};



function CustomerProfilePanel() {
    const echoUser = useSelector((state) => state.echoUser);

    const [name, setName] = React.useState(echoUser?.profile?.name || "");
    const [company, setCompany] = React.useState(echoUser?.profile?.company || "");
    const [title, setTitle] = React.useState(echoUser?.profile?.title || "");
    const [address, setAddress] = React.useState(echoUser?.profile?.address || "");
    const [birthday, setBirthday] = React.useState(echoUser?.profile?.birthday || "");
    const [phone, setPhone] = React.useState(echoUser?.profile?.phone || "");

    const [isEditingName, setEditingName] = React.useState(false);
    const [isEditingCompany, setEditingCompany] = React.useState(false);
    const [isEditingTitle, setEditingTitle] = React.useState(false);
    const [isEditingAddress, setEditingAddress] = React.useState(false);
    const [isEditingBirthday, setEditingBirthday] = React.useState(false);
    const [isEditingPhone, setEditingPhone] = React.useState(false);


    const [avatarUrl, setAvatarUrl] = useState('');


    useEffect(() => {

        const fetchAvatar = async () => {
            try {
                const response = await axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar.filename}`, {
                    responseType: 'blob' // Important for handling binary data
                });
                const imageUrl = URL.createObjectURL(response.data);
                setAvatarUrl(imageUrl);
            } catch (error) {
                console.error('Error fetching avatar:', error);
            }
        };

        fetchAvatar();
        // try{
        //     const res = axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar}`);
        //     console.log(res)
        // } catch (err) {
        //     console.log(err)
        // }

    }, [])
    // console.log(currentUser)

    const handleEditName = (event) => {
        setName(event.target.value);
    }
    const handleEditCompany = (event) => {
        setCompany(event.target.value);
    }
    const handleEditTitle = (event) => {
        setTitle(event.target.value);
    }
    const handleEditAddress = (event) => {
        setAddress(event.target.value);
    }
    const handleEditBirthday = (event) => {
        setBirthday(event.target.value);
    }
    const handleEditPhone = (event) => {
        setPhone(event.target.value);
    }

    const handleEditAccountName = () => {
        setEditingName(true)
    }

    const handleEditAccountCompany = () => {
        setEditingCompany(true)
    }

    const handleEditAccountTitle = () => {
        setEditingTitle(true)
    }

    const handleEditAccountAddress = () => {
        setEditingAddress(true)
    }

    const handleEditAccountBirthday = () => {
        setEditingBirthday(true)
    }

    const handleEditAccountPhone = () => {
        setEditingPhone(true)
    }

    const handleEditOff = () => {
        setEditingName(false);
        setEditingCompany(false);
        setEditingTitle(false);
        setEditingAddress(false);
        setEditingBirthday(false);
        setEditingPhone(false);
    }


    // const dispatch = useDispatch();


    // const [profile, setProfile] = useState([]);

    const handleSave = () => {
        const updatedProfile = {
            name: name,
            company: company,
            title: title,
            address: address,
            birthday: birthday,
            phone: phone,
            email: echoUser?.profile?.email,
        };
        console.log(updatedProfile)

        axiosInstance.post("/customers/update", updatedProfile)
            .then(response => {
                // Handle success, maybe update UI or give feedback
                console.log("Profile updated successfully:", response.data);
            })
            .catch(error => {
                // Handle error
                console.error("Error updating profile:", error);
            });
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        if (file instanceof Blob) {

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            console.log(file)
        } else {
            console.error('No file selected.');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('profilePicture', selectedFile);
        formData.append('email', echoUser?.profile?.email);
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
        try {
            const res = await axiosInstance.post(`/customers/upload/${echoUser?.profile?.customerId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = (e) => {
        e.stopPropagation()

        setOpen(false);
    }


    return (
        <Grid container>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    fullWidth
                    variant="outlined"
                    sx={{
                        borderRadius: "20px",
                        background: "#39394a",
                        color: "white"
                    }}>
                    <CardContent>
                        <List>
                            <ListItem className='settingsHover' onClick={handleOpen}>
                                {/* <Button variant='contained' sx={{ padding: "20px" }} >Change Profile Picture</Button> */}
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 500
                                    }}>Profile Picture:</Typography>


                                <Avatar
                                    id="profile-picture"
                                    label="Profile Picture"
                                    alt={echoUser?.profile?.name}
                                    src={avatarUrl || previewUrl}
                                    sx={{
                                        ml: 1,
                                        width: 100,
                                        height: 100
                                    }} />
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={open}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    slots={{ backdrop: Backdrop }}
                                    slotProps={{
                                        backdrop: {
                                            timeout: 500,
                                        },
                                    }}
                                >
                                    <Box sx={style}>
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h3"
                                            component="h2">
                                            Profile Picture
                                        </Typography>
                                        <form
                                            onSubmit={
                                                handleSubmit
                                            }
                                            method="post"
                                            enctype="multipart/form-data">
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: "30px"
                                                }}>
                                                <Input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    accept="image/*"
                                                    name="profilePicture"
                                                    sx={{ display: 'none' }}
                                                    id="profile-picture-input"
                                                />
                                                <label htmlFor="profile-picture-input">
                                                    <Avatar
                                                        alt="Profile Picture"
                                                        src={previewUrl}
                                                        sx={{
                                                            width: 250,
                                                            height: 250,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <CameraAltIcon />
                                                    </Avatar>
                                                </label>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                sx={{ borderRadius: "20px" }}>
                                                Change
                                            </Button>
                                            {/* <Button variant="contained" type="submit" onClick={(e) => { e.preventDefault(); handleClose(); }}>Close</Button> */}
                                        </form>
                                    </Box>
                                </Modal>
                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingName ? (
                                    <TextField
                                        id="name-edit"
                                        label="Name:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditName}
                                        onBlur={handleEditOff}
                                        defaultValue={name}
                                        value={name}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Name:"
                                    fullWidth
                                    secondary={name}
                                    value={name}
                                    secondaryTypographyProps={{ style: { color: 'white' } }}
                                    onClick={handleEditAccountName} />)}
                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingCompany ? (
                                    <TextField
                                        id="company-edit"
                                        label="Company:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditCompany}
                                        onBlur={handleEditOff}
                                        defaultValue={company}
                                        value={company}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Company:"
                                    fullWidth
                                    secondary={company}
                                    value={company}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleEditAccountCompany} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingTitle ? (
                                    <TextField
                                        id="title-edit"
                                        label="Title:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditTitle}
                                        onBlur={handleEditOff}
                                        defaultValue={title}
                                        value={title}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Title:"
                                    fullWidth
                                    secondary={title}
                                    value={title}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleEditAccountTitle} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingAddress ? (
                                    <TextField
                                        id="address-edit"
                                        label="Address:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditAddress}
                                        onBlur={handleEditOff}
                                        multiline
                                        defaultValue={address}
                                        value={address}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Address:"
                                    fullWidth
                                    secondary={address}
                                    value={address}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleEditAccountAddress} />)}
                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingBirthday ? (
                                    <TextField
                                        id="birthday-edit"
                                        label="Birthday:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditBirthday}
                                        onBlur={handleEditOff}
                                        defaultValue={birthday}
                                        value={birthday}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Birthday:"
                                    fullWidth
                                    secondary={birthday}
                                    value={birthday}
                                    secondaryTypographyProps={{
                                        style: {
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleEditAccountBirthday} />)}

                            </ListItem>
                            <ListItem className="settingsHover">
                                {isEditingPhone ? (
                                    <TextField
                                        id="phone-edit"
                                        label="Phone:"
                                        variant="filled"
                                        fullWidth
                                        onChange={handleEditPhone}
                                        onBlur={handleEditOff}
                                        defaultValue={phone}
                                        value={phone}
                                        sx={{
                                            '& .MuiFilledInput-root': {
                                                color: 'white'
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'white', // Optional: Set the label color
                                            }
                                        }}
                                        autoFocus />

                                ) : (<ListItemText
                                    primary="Phone:"
                                    fullWidth
                                    secondary={phone}
                                    value={phone}
                                    secondaryTypographyProps={{
                                        style:
                                        {
                                            color: 'white'
                                        }
                                    }}
                                    onClick={handleEditAccountPhone} />)}

                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Email:"
                                    secondary={echoUser?.profile?.email}
                                    secondaryTypographyProps={{
                                        style:
                                        {
                                            color: 'white'

                                        }
                                    }}></ListItemText>
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions sx={{ padding: "30px" }}>
                        <Button variant="contained" onClick={handleSave}>
                            Save
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CustomerProfilePanel;