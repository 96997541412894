import { SET_CURRENT_USER, UPDATE_CURRENT_USER } from '../actionTypes';

// Initial state of the store for user info
const DEFAULT_STATE = {

    isAuthenticated: false,
    access_token: '',
    user: {},
    profile: {}
}

const currentUserState = (state = DEFAULT_STATE, action) => {

    switch (action.type) {

        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: action.payload.user.isAuthenticated,
                access_token: action.payload.user.token,
                user: action.payload.user.user,
                profile: action.payload.user.profile,
            };
        case UPDATE_CURRENT_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload.user }
            };
        default:
            return state;
    }
}

export default currentUserState;