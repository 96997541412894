
// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid2';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Images
import trainingImage from '../../images/training.gif';
import cstHvac from '../../images/cst_home/cst_hvac.jpg';

function Training() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: '20px'
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    Training
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card
                            sx={{
                                borderRadius: "9px"
                            }}
                            elevation={0}>
                            <CardContent>
                                <Typography
                                    variant="h2"
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    Ammonia Safety, RMP/PSM/CalARP Refresher Training
                                </Typography>
                                <Typography variant='h4'>
                                    Contact us for upcoming dates
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={trainingImage}
                                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                alt="training">
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <img src={cstHvac} width={'90%'} alt="hvac" />

                    </Grid>
                </Grid>
            </Container>
        </Box>

    )
}

export default Training;