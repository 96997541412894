
import React, { useEffect, useState } from 'react';

// Material UI Components
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Material UI Icons
import Snackbar from '@mui/material/Snackbar';

// Axios API
import axiosInstance from "../../utils/axiosInstance";



function UpdateCompanyForm() {
    const [loading, setLoading] = useState(true);

    const [companyList, GetCompanies] = useState([]);
    const [companyCount, GetCompaniesCount] = React.useState(0);

    const [facilityList, GetFacilities] = useState([]);

    const [company, setCompany] = React.useState(null);

    const [facility, setFacility] = React.useState(null);

    const [companySerial, setCompanySerial] = React.useState("");
    const [companyStatus, setCompanyStatus] = React.useState("ACTIVE");
    const [companyWebsite, setCompanyWebsite] = React.useState("");
    const [companyDescription, setCompanyDescription] = React.useState("");

    const [selectCompany, GetSelectCompany] = React.useState({});

    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [snackbarOpenRemove, setSnackbarOpenRemove] = useState(false);

    const companySearch = [];
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {

        axiosInstance
            .get(`/companies`).then(response => {
                // console.log(response.data);
                GetCompanies(response.data);
                GetCompaniesCount(response.data.length);
                console.log("User Fetched and Companies Successfully");
                // console.log(companyList)
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(true);
            });



    }, [GetCompanies, GetFacilities])

    /**
        * Updates the company and facility information when a company name is selected.
        * 
        * This function is called when the user selects a company from the Autocomplete 
        * dropdown. It fetches the company details from the `/companies/name/:companyName` 
        * API endpoint and updates the corresponding state variables. It also fetches 
        * the facilities associated with the selected company from the `/facilities/:companyName` 
        * API endpoint and updates the `facilityList` state.
        * 
        * @param {Event} e The event object.
        * @param {String} value The value of the company name selected from the Autocomplete.
        * @returns {Void}
    */
    const handleCompanyChange = (e, value) => {
        // console.log(e.target.value);
        setCompany(value);
        if (value !== "") {
            axiosInstance
                .get(`/companies/name/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    // GetFacilities(response.data);
                    GetSelectCompany(response.data);
                    const company = response.data[0] ?? {};

                    setCompanyStatus(company.status ?? "");
                    setCompanySerial(company.serial ?? "");
                    setCompanyWebsite(company.website ?? "");
                    setCompanyDescription(company.description ?? "");
                    console.log(response.data)
                })
                .catch((error) => {
                    console.error(error);
                });
            axiosInstance
                .get(`/facilities/${value}`)
                .then((response) => {
                    // Update the albums state with the fetched data
                    // console.log(response)
                    GetFacilities(response.data);
                    console.log(response.data)
                })
                .catch((error) => {
                    console.error(error);
                });

            console.log("PICTURE")


            axiosInstance('/companies/images/AB company.png_1724189778331').then(response => {
                console.log(response.data)
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    /**
        * Adds the serial number of the company
        * @param {Event} e The event object.
        * @param {String} value The value of the status.
        * @returns {Void}
    */
    const handleCompanySerialChange = (e, value) => {
        setCompanySerial(e.target.value);
    }

    /**
        * Adds a website of the company
        * @param {Event} e The event object.
        * @param {String} value The value of the status.
        * @returns {Void}
    */
    const handleCompanyWebsiteChange = (e, value) => {
        setCompanyWebsite(e.target.value);
    }

    /**
        * Adds the description of the company
        * @param {Event} e The event object.
        * @param {String} value The value of the description.
        * @returns {Void}
    */
    const handleCompanyDescriptionChange = (e, value) => {
        setCompanyDescription(e.target.value);
    }

    /**
        * Adds a status of ACTIVE, PENDING, or INACTIVE to the company
        * @param {Event} e The event object.
        * @param {String} value The value of the status.
        * @returns {Void}
    */
    const handleCompanyStatusAdd = (e, value) => {
        if (e.target.value === "ACTIVE") {
            setCompanyStatus("ACTIVE");
        }
        else if (e.target.value === "PENDING") {
            setCompanyStatus("PENDING");
        }
        else if (e.target.value === "INACTIVE") {
            setCompanyStatus("INACTIVE");
        }
    }

    /**
        * On Company Update Submit
        * @param {Event} e The event object.
        * @returns {Void}
    */
    const updateCompanySubmit = async (e) => {
        console.log(company, facility, companySerial);

        try {
            const response = await axiosInstance
                .post(`/companies`, {
                    company_name: company,
                    description: companyDescription,
                    serial: companySerial,
                    status: companyStatus,
                    website: companyWebsite,
                })

            setSnackbarMessage('Request successful! \n Company: ' +
                response.data.company_name +
                "\nStatus: " +
                response.data.status +
                "\nSerial: " +
                response.data.serial +
                "\nWebsite: " +
                response.data.website +
                "\nDescription: " +
                response.data.description +
                "\nUPDATED");
            setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('Error: ' + error.message);
            setSnackbarOpen(true);
        }
    }


    companyList?.map((company) => {
        companySearch.push(
            {
                label: company.company_name,
                companyObject: company
            })
    })



    const filteredCompanies = searchQuery ? companyList?.filter((word) => word.label.includes(searchQuery)) : companySearch


    /**
        * Closes the snackbar.
        * 
        * This function is called when the user clicks the close button or outside the 
        * Snackbar. It updates the state variables `snackbarOpen` and `snackbarOpenRemove` 
        * to `false`, effectively hiding the Snackbar.
        * 
        * @returns {Void}
    */
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
        setSnackbarOpenRemove(false);
    };


    return (
        <Card
            elevation={0}
            variant="outlined"
            sx={{
                borderRadius: "10px"
            }}>
            <CardContent>
                <Grid
                    container
                    spacing={5}>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Typography variant='h2'>
                            Update Company
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Typography variant='h3'>
                            Company
                        </Typography>
                        <Autocomplete
                            value={company}
                            onChange={handleCompanyChange}
                            disablePortal
                            id="company-search"
                            options={filteredCompanies.map((word) => word.label)}
                            sx={{ width: "100%" }}
                            renderInput={(params) => <TextField {...params} required label="Search Companies" />}
                        />
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl >
                            <Typography variant='h3'>
                                Company Status
                            </Typography>


                            <TextField
                                id="company-status"
                                select
                                onChange={handleCompanyStatusAdd}
                                value={companyStatus}
                                label="Company Status"
                                sx={{ color: "black", width: "100%" }}
                                variant="outlined" >
                                <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>

                            </TextField>
                        </FormControl>

                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleCompanySerialChange}>
                            <Typography variant='h3'>
                                Company Serial
                            </Typography>

                            <TextField
                                id="company-serial"
                                label="Company Serial"
                                variant="outlined"
                                value={companySerial}
                            />
                        </FormControl>

                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl>
                            <Typography variant='h3'>
                                Company Logo
                            </Typography>
                            {selectCompany[0]?.logo?.toString()}

                            <Avatar
                                id="company-logo"
                                label="Company Logo"
                                variant="outlined" />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <FormControl onChange={handleCompanyWebsiteChange}>
                            <Typography variant='h3'>
                                Company Website
                            </Typography>

                            <TextField
                                id="company-website"
                                label="Company Website"
                                variant="outlined"
                                value={companyWebsite}
                            />
                        </FormControl>
                    </Grid>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <FormControl sx={{ width: "100%" }} onChange={handleCompanyDescriptionChange}>
                            <Typography variant='h3'>
                                Company Description
                            </Typography>

                            <TextField
                                multiline
                                rows={6}
                                id="company-description"
                                label="Company Description"
                                variant="outlined"
                                value={companyDescription} />
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={updateCompanySubmit}>Update Company</Button>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </CardActions>
        </Card>
    )
}

export default UpdateCompanyForm;