
// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

// Images
import servicesImage from '../../images/services.gif';
import echoImage from '../../images/products/echo.png';
import cstPipes from '../../images/cst_home/cst_pipes.jpg';

function Service() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    Service
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>

                    <Grid
                        size={{
                            md: 8,
                            xs: 12
                        }}>
                        <Card
                            sx={{
                                borderRadius: "9px"
                            }}
                            elevation={0}>
                            <CardContent>
                                <Typography
                                    variant="h2"
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        color: "linear-gradient(5deg, #283f99, #4686cd)",
                                        fontWeight: 600
                                    }}>
                                    <span
                                        role="heading"
                                        className='gradientGlow'>Cold Storage Technologies </span>
                                    offers a fleet of highly experienced, well trained, fully outfitted service technicians.
                                </Typography>
                                <Typography variant='h4'>
                                    Our technicians are fully prepared for nearly any problem or refrigeration system, ammonia or otherwise.
                                </Typography>
                                <Box
                                    sx={{
                                        padding: "20px"
                                    }}>
                                    <Divider></Divider>
                                </Box>
                                <Typography
                                    variant='h3'
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    Call our service department today.
                                </Typography>
                                <Typography variant='h4'>
                                    Phone:
                                    <Link href={`tel:+15592750110`}>
                                        (559) 275-0110
                                    </Link>
                                </Typography>

                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={servicesImage}
                                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                alt="services">
                            </CardMedia>
                            <CardContent>

                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* <img src={cstPipes} width={'90%'} /> */}


                </Grid>
            </Container>
            <Box
                style={{
                    width: '100%',   // Full width of the page
                    height: '600px', // Fixed height for the banner (adjust as needed)
                    overflow: 'hidden' // Ensures that the content that exceeds the height will be hidden (cropping effect)
                }}
            >
                <img
                    src={cstPipes}
                    style={{
                        width: '100%',
                        height: '100%', // Ensures the image height fills the container
                        objectFit: 'cover', // Crops the image to maintain aspect ratio while covering the container
                    }}
                    alt="Banner"
                />
            </Box>
        </Box>

    )
}

export default Service;