import React, { useEffect, useState } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

// Axios API
import axiosInstance from "../../../utils/axiosInstance";


// React Redux
import { useSelector } from "react-redux";

import CustomerProfilePanel from "../../../components/CustomerProfilePanel";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

/**
    * Settings Component
    * @returns {JSX.Element} The rendered Settings Component.
*/
function Settings() {
    const echoUser = useSelector((state) => state.echoUser);
    const [value, setValue] = React.useState(0);


    /**
        * Handle Settings Panel Change
        * @param {Event} event The event object.
        * @param {Number} newValue The new value.
        * @returns {Void}
    */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [avatarUrl, setAvatarUrl] = useState('');


    useEffect(() => {

        const fetchAvatar = async () => {
            try {
                const response = await axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar.filename}`, {
                    responseType: 'blob' // Important for handling binary data
                });
                const imageUrl = URL.createObjectURL(response.data);
                setAvatarUrl(imageUrl);
            } catch (error) {
                console.error('Error fetching avatar:', error);
            }
        };

        fetchAvatar();
        // try{
        //     const res = axiosInstance.get(`/customers/profile-pictures/${echoUser?.profile?.avatar}`);
        //     console.log(res)
        // } catch (err) {
        //     console.log(err)
        // }

    }, [])
    // console.log(currentUser)


    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Settings
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                            display: 'flex',
                            height: "100%",
                            borderRadius: 8,
                            outline: "1px solid rgba(0, 0, 0, 0.15)",
                            padding: "50px",
                            background: "#242436",
                            color: "white"
                        }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                borderRight: 1,
                                minWidth: 200,
                                borderColor: 'divider',
                            }}
                        >
                            <Tab
                                style={{
                                    color: "white"
                                }}
                                label="Account" {...a11yProps(0)} />
                            <Tab
                                style={{
                                    color: "white"
                                }}
                                label="Notifications" {...a11yProps(1)} />
                        </Tabs>
                        <Box sx={{
                            width: "100%"
                        }}>
                            <TabPanel
                                value={value}
                                index={0}>
                                <CustomerProfilePanel />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}>
                                {/* <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    borderRadius: "20px"
                                }}>
                                <CardContent>
                                    <Grid item md={12} xs={12}>

                                        <Typography
                                            variant="h2"
                                            align="center">Customer Sign Up
                                        </Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            margin="normal"
                                            type="email"
                                            label="Email"
                                            name="signInEmail"
                                            value={signInEmail}
                                            error={emailError ? true : false}
                                            helperText={emailError}
                                            onChange={handleSignInCustomer}
                                        />
                                        <TextField
                                            required
                                            fullWidth
                                            margin="normal"
                                            type="password"
                                            label="Password"
                                            name="signInPassword"
                                            value={signInPassword}
                                            error={passwordError ? true : false}
                                            helperText={passwordError}
                                            onChange={handleSignInCustomer}
                                        />
                                        <Button
                                            style={{ marginTop: 40 }}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            onClick={handleSignInSubmit}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </CardContent>
                            </Card> */}
                            </TabPanel>
                        </Box>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}

export default Settings;