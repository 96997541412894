
// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

// Images
import aboutImage from '../../images/aboutus.gif';
import cstEvaporator from '../../images/cst_home/cst_evaporator.jpg';

function AboutUs() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: '20px'
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    About Us
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>

                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography
                                    variant='h2'
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    We are the largest design build refrigeration and electrical contractor in California specializing in industrial refrigeration systems, service, controls, regulatory compliance and energy efficiency.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 6,
                            xs: 12
                        }}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={aboutImage}
                                sx={{
                                    padding: "1em 1em 0 1em",
                                    objectFit: "contain"
                                }}
                                alt="about us">
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <img src={cstEvaporator} width={'100%'} alt="evaporator" />

                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Card
                            sx={{
                                borderRadius: "20px",
                                padding: "50px"
                            }}
                            elevation={0}
                            variant="outlined">
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography
                                    variant='h3'
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    The design team has over 25 years of experience working together.
                                    Services include:
                                </Typography>
                                <List
                                    sx={{
                                        listStyleType: 'disc',
                                        padding: '20px'
                                    }}>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            Ammonia and Freon system design, installation and service.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            24 hr refrigeration system remote monitoring and response.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            Control systems that are reliable, easy to use and inexpensive to maintain.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            RMP/PSM/CalARP programs, Compliance Audits, Updates and PHA’s
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            Ammonia. RMP/PSM/CalARP Training Energy Efficiency Consulting.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            Energy Use Analysis including refrigeration models for utility incentives.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            “GREEN” Refrigeration Design.
                                        </Typography>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            display: 'list-item'
                                        }}>
                                        <Typography>
                                            Chilled water distribution systems for Food Processing.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    )
}

export default AboutUs;