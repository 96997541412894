import axios from "axios";
import Cookies from "js-cookie";

var serverURL = process.env.REACT_APP_DEV_SERVER_URL;

const role = Cookies.get("chooseCustomerEmployee");
const employeeToken = Cookies.get("employeeToken");
const customerToken = Cookies.get("customerToken");

if (!role || (!employeeToken && !customerToken)) {
  console.error("Missing role or token(s)");
}


const bearerToken = role === "empolyee"
  ? employeeToken
  : customerToken

const axiosInstance = axios.create({
  baseURL: serverURL,
  responseType: "json",
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${bearerToken}`,
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;