import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(38, 56, 168, 1)",
    },
    secondary: {
      main: "#375fd7",
    },
    nav: {
      main: "#ffffff",
    },
    card: {
      main: "#ffffff",
    },
    snack: {
      main: "#ffffff",
    },
    error: {
      main: "#d32f2f",
    },
    background: {
      main: "#f0f2ff",
    },
    text: {
      main: "#000000",
    },
  },
  typography: {
    WebkitFontSmoothing: 'antialiased',
    lineHeight: "1.5",
    letterSpacing: '-0.02em !important',
    h1: {
      fontWeight: 400,
      fontSize: "2.9rem", // Customize the font size for h1
    },
    h2: {
      fontWeight: 400,
      fontSize: "2rem", // Customize the font size for h2
    },
    h3: {
      fontWeight: 400,
      fontSize: "1.45rem", // Customize the font size for h3
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.15rem", // Customize the font size for h4
    },
    h5: {
      fontWeight: 500,
      fontSize: "1rem", // Customize the font size for h5
    },
    h6: {
      fontWeight: 500,
      fontSize: ".95rem", // Customize the font size for h6
    },
    p: {
      fontWeight: 600,
      fontSize: "0.85rem",
    },
    // ... Add custom styles for h3, h4, h5, etc. as needed
  },
});

export default theme;