import React from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import EventChart from '../../../components/apex/EventChart';

/**
 * Events Component
 * @returns {JSX.Element} The rendered Events Component.
 */
function Events() {

    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Typography>
                            Events
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Container>
                                <EventChart />
                            </Container>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 6,
                    xs: 6
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
        </Grid>
    );
}

export default Events;
