import React, { useEffect } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import ProfilePanel from '../../../components/ProfilePanel';
import AddCustomerPanel from '../../../components/AddCustomerPanel';
import ManageRolesPanel from '../../../components/ManageRolesPanel';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

/**
    * Settings Component
    * @returns {JSX.Element} The rendered Settings Component.
*/
function Settings({ onChange }) {
    const [value, setValue] = React.useState(0);





    /**
        * Handle Settings Panel Change
        * @param {Event} event The event object.
        * @param {Number} newValue The new value.
        * @returns {Void}
    */
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

    }, [])
    // console.log(currentUser)



    // const dispatch = useDispatch();


    // const [profile, setProfile] = useState([]);



    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Settings
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'background.paper',
                            display: 'flex',
                            height: "100%",
                            borderRadius: 8,
                            outline: "1px solid rgba(0, 0, 0, 0.15)",
                            padding: "50px"
                        }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                borderRight: 1,
                                minWidth: 200,
                                borderColor: 'divider',
                            }}
                        >
                            <Tab label="Account" {...a11yProps(0)} />
                            <Tab label="Add Customer" {...a11yProps(1)} />
                            <Tab label="Manage Roles" {...a11yProps(2)} />

                        </Tabs>
                        <Box sx={{ width: "100%" }}>
                            <TabPanel
                                data-testid="profile-panel"
                                value={value}
                                index={0}>
                                <ProfilePanel />
                            </TabPanel>
                            <TabPanel
                                data-testid="add-customer-panel"

                                value={value}
                                index={1}>
                                <AddCustomerPanel />
                            </TabPanel>
                            <TabPanel
                                data-testid="manage-roles-panel"

                                value={value}
                                index={2}>
                                <ManageRolesPanel />
                            </TabPanel>
                        </Box>

                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}

export default Settings;