import React, { useEffect, useState } from 'react';

// Material UI Components
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';

// Axios API
import axiosInstance from "../utils/axiosInstance";

function ManageRolesPanel() {

    const [value, setValue] = React.useState(0);

    const roles = ["Admin", "HR", "Mechanical", "Electrical", "Construction", "Controls"];
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [email, setEmail] = React.useState("")

    useEffect(() => {

    }, [])
    // console.log(currentUser)

    const handleSaveRole = () => {
        const updatedRoles = {
            roles: selectedRoles,
            email: email,
        };
        console.log(updatedRoles)

        axiosInstance.post("/users/roles", updatedRoles)
            .then(response => {
                // Handle success, maybe update UI or give feedback
                console.log("Roles updated successfully:", response.data);
            })
            .catch(error => {
                // Handle error
                console.error("Error updating roles:", error);
            });
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    return (
        <Grid container>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    fullWidth
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <TextField
                            id="email-manage"
                            label="Email:"
                            variant="filled"
                            fullWidth
                            onChange={handleEmail}
                            //  onBlur={handleEditOff}
                            defaultValue={email}
                            value={email}
                            autoFocus></TextField>
                        <Autocomplete
                            multiple
                            fullWidth
                            id="tags-standard"
                            options={roles}
                            getOptionLabel={(option) => option}
                            value={selectedRoles}
                            onChange={(event, newValue) => {
                                setSelectedRoles(newValue)
                            }}
                            // defaultValue={[top100Films[13]]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Manage Role"
                                    placeholder="Roles"
                                />
                            )}
                        />
                    </CardContent>
                    <CardActions sx={{ padding: "30px" }}>
                        <Button variant="contained" onClick={handleSaveRole}>Save</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

export default ManageRolesPanel;