import React, { useEffect, } from 'react';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

// Images
import controlsImage from '../../images/controls.gif';
import echoImage from '../../images/products/echo.png';

// Motion API
import { motion, useScroll } from "framer-motion";

function Controls() {


    const { scrollY } = useScroll();
    const [isVisible, setIsVisible] = React.useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollY.current >= 100) { // Change the scroll threshold as needed
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        scrollY.on("change", handleScroll);

        return () => {
            scrollY.clearListeners();
        };
    }, [scrollY]);

    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Container>
                                <Typography role="heading" variant="h1">
                                    Controls
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>

                    <Grid
                        size={{
                            md: 8,
                            xs: 12
                        }}>
                        <Typography variant="h2"
                            style={{
                                fontFamily: "IBM Plex Sans",
                                fontWeight: 600
                            }}>
                            CST controls systems deliver proven, bottom-line results for a wide variety of refrigeration applications.
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Card elevation={0}
                            sx={{
                                borderRadius: "9px"
                            }}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={controlsImage}
                                sx={{
                                    padding: "1em 1em 0 1em",
                                    objectFit: "contain"
                                }}
                                alt="controls">
                            </CardMedia>
                        </Card>
                    </Grid>
                    <Box>
                        <Container>
                            <Box
                                sx={{
                                    padding: "100px"
                                }}>
                                <Divider
                                    sx={{
                                        height: "5px",
                                        background: "linear-gradient(to right, #375fd7 50%, #afd5ff 50%)"
                                    }}></Divider>
                            </Box>
                            <motion.div
                                initial={{
                                    scale: .95
                                }}
                                animate={{
                                    scale: isVisible ? 1 : .95
                                }}
                                transition={{
                                    duration: .6
                                }}
                            >
                                <Typography
                                    variant='h2'
                                    sx={{
                                        fontFamily: 'IBM Plex Sans',
                                        fontWeight: 600,
                                        textAlign: "center",
                                        padding: "100px"
                                    }}>
                                    <span className='gradientGlow'>
                                        CST offers controls products that enable us to provide unmatched customer support and reliable system operations far into the future.
                                    </span>
                                </Typography>
                            </motion.div>
                        </Container>
                        <Box
                            sx={{
                                padding: "100px"
                            }}>
                            <Divider
                                sx={{
                                    height: "5px",
                                    background: "linear-gradient(to left, #375fd7 50%, #afd5ff 50%)"
                                }}></Divider>
                        </Box>
                    </Box>
                    <Grid
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <img src={echoImage} width="100%" alt="echo" />


                    </Grid>
                </Grid>

            </Container>

            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                {/* <motion.div
                    initial={{
                        scale: .55
                    }}
                    animate={{
                        scale: isVisible ? 1 : .55
                    }}
                    transition={{
                        duration: 1.3
                    }}
                > */}
                <Box
                    sx={{
                        background: "linear-gradient(353deg, #283f99, #2377d5)",
                        color: " white"
                    }}>
                    <Container>

                        <Typography
                            variant='h3'
                            sx={{
                                fontFamily: 'IBM Plex Sans',
                                fontWeight: 600,
                                textAlign: "center",
                                padding: "100px"
                            }}>
                            Our flexible refrigeration control systems offer an extensive list of features that enable CST to provide support and troubleshooting services for CST designed and built industrial refrigeration control systems.
                        </Typography>
                    </Container>
                </Box>
                {/* </motion.div> */}
            </Grid>
            <Container>
                <Grid container>
                    <Grid
                        padding={20}
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <motion.div
                            initial={{
                                scale: 0.9
                            }}
                            animate={{
                                scale: isVisible ? 1 : 0.5
                            }}
                            transition={{
                                duration: 1
                            }}
                        >
                            <Card
                                sx={{
                                    padding: "100px",
                                    borderRadius: "20px"
                                }}
                                elevation={0}
                                variant="outlined">
                                <CardContent>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            fontWeight: 600
                                        }}>
                                        These features include the following…
                                    </Typography>
                                    <List
                                        sx={{
                                            listStyleType: 'disc',
                                            padding: '20px'
                                        }}>
                                        <ListItem sx={{
                                            display: 'list-item'
                                        }}>
                                            <Typography>
                                                remote access via Internet connections
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                data logging, archiving and flexible reporting features
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                alarm dial-out and paging
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                flexible and functional operator interface using a web browser
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                rugged touch panels for local operator access to the control system
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                state-of-the-art hardware that is reliable and easy to support and maintain
                                            </Typography>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            <Typography>
                                                reliable program and data storage using Compact Flash (CF) technology eliminating the need for backup batteries.
                                            </Typography>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>

        </Box>
    )
}

export default Controls;