import { SET_CURRENT_USER, SET_ECHO_USER } from "../actionTypes";

export const updateUser = (user) => (dispatch, getState) => {
  try {
    console.log("REDUX Hoth User");
    console.log(user, getState());
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        user,
      },
    });
  } catch (error) {
    console.error("Error", error);
  }
};

export const updateEchoUser = (echoUser) => (dispatch, getState) => {
  try {
    console.log("REDUX Echo User");
    console.log(echoUser, getState());
    dispatch({
      type: SET_ECHO_USER,
      payload: {
        echoUser,
      },
    });
  } catch (error) {
    console.error("Error", error);
  }
};
