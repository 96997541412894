import React, { useEffect, useState } from 'react';

// Material UI Components
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


import axios from "axios";

import KPIChart from '../../../components/apex/KPIChart';
import BudgetChart from '../../../components/apex/BudgetChart';

/**
    * Status Component
    * @returns {JSX.Element} The rendered Status Component.
*/
function Status() {

    const elasticSearch = []

    const [articles, setArticles] = useState([]);

    const fetchNews = async () => {
        const news = {
            method: 'GET',
            url: 'https://google-news22.p.rapidapi.com/v1/search',
            params: {
                q: 'Refrigeration OR Food OR Agriculture OR Cold Storage OR Technology OR Valley OR Fresno OR Ammonia OR Electrical OR Mechanical OR Data OR Center -Bitcoin -crypto -sport',
                country: 'us',
                language: 'en',
                from: "1y",
                to: "1d"
            },
            headers: {
                'x-rapidapi-key': process.env.REACT_APP_NEWS_KEY,
                'x-rapidapi-host': 'google-news22.p.rapidapi.com'
            },
        };
        try {

            const response = await axios.request(news);
            setArticles(response.data.data);
            console.log(response.data)
        } catch (error) {
            console.error('Failed to fetch news:', error);
        }
    };


    useEffect(() => {
        fetchNews();

    }, []);


    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"
                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Overview
                        </Typography>
                        <Box
                            sx={{
                                padding: "20px"
                            }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={elasticSearch}
                                renderInput={(params) => <TextField fullWidth {...params} label="Search CST" />}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>


                    <Grid
                        container
                        padding={4}
                        spacing={5}>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    borderRadius: "20px"
                                }}>
                                <CardContent>
                                    <Typography variant="h2">
                                        Announcements
                                    </Typography>
                                    <Typography>Google Chat, Google Drive, Google Sheets, and Jotform Apis</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>
                            <Card
                                elevation={0}
                                variant="outlined"
                                sx={{
                                    borderRadius: "20px"
                                }}>
                                <CardContent>
                                    <Typography variant="h2">
                                        Budget
                                    </Typography>
                                    <Typography>CST Totals, Charts</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            size={{
                                md: 6,
                                xs: 12
                            }}>
                            <Typography variant="h2">
                                KPI's
                            </Typography>
                            <Container>
                                <KPIChart />
                            </Container>
                        </Grid>
                        <Grid
                            size={{
                                md: 6,
                                xs: 12
                            }}>
                            <Typography variant="h2">
                                Overview
                            </Typography>
                            <Container>
                                <BudgetChart />
                            </Container>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        variant="outlined"
                        sx={{
                            borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Typography variant="h2">
                                News
                            </Typography>
                            <Grid container spacing={2} padding={5}>
                                {Array.isArray(articles) && articles?.map((article, index) => (
                                    <Grid
                                        key={"grid"+index}
                                        size={{
                                            md: 4,
                                            xs: 4
                                        }}>
                                        <Card variant="outlined" elevation={0} sx={{ borderRadius: "15px" }}>
                                            <Link href={article?.url} target="_blank" rel="noopener noreferrer">

                                                <CardActionArea>

                                                    <CardMedia
                                                        component="img"
                                                        height="200"
                                                        image={article?.thumbnail}
                                                        alt={article?.title}
                                                        sx={{ objectFit: 'cover' }}  // Ensures the image covers the space without distortion
                                                    />
                                                    {/* <img src={article?.top_image} width={350} /> */}
                                                    <CardContent sx={{ flexGrow: 1 }}>
                                                        <Typography variant="h3" component="h1" gutterBottom>
                                                            {article.title}

                                                        </Typography>
                                                        {/* <Typography variant="body2" color="textSecondary" mt={1}>

                                                            {article.url}

                                                        </Typography> */}
                                                        <Typography variant="body2" color="textSecondary" mt={1}>
                                                            {Date(article.date)}
                                                        </Typography>
                                                    </CardContent>

                                                </CardActionArea>
                                            </Link>

                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </Grid>
    )
}

export default Status;