import React from 'react';
import ReactDOM from 'react-dom/client';

// Points to Main Application Routes
import App from './App';

// Material UI Components
import { ThemeProvider } from "@mui/material/styles";

// Main Custom Theme Variables and Styles
import theme from "./utils/theme.js";

// React Redux
import { Provider } from "react-redux";
import { store } from "./store";

// Google Auth API
import { GoogleOAuthProvider } from "@react-oauth/google"

// Custom Style
import './index.css';

// Performance, Metrics, and Reports
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <Provider store={store}>

        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
