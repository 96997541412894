import React, { useEffect, useState } from 'react';

// Material UI Components
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// Material UI Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

function VisualToolbar(props) {
    const { canvasRef } = props;

    const [currentTool, setCurrentTool] = useState('icon');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [fontSize, setFontSize] = useState(5);
    const [fontColor, setFontColor] = useState('#000000');
    const [zoomLevel, setZoomLevel] = useState(1);
    const [textValue, setTextValue] = useState('');
    const [selectedIcon, setSelectedIcon] = useState(null);
    const gridSize = 10; // Define grid size
    const gridColor = 'lightgray'; // Define grid color
    const [drawnItems, setDrawnItems] = useState([]); // State to store drawn items

    let dragStartX, dragStartY;
    let selectedItemId;
    let isDraggingItem;
    let isResizingItem;
    let resizeHandle;

    /**
        * Prevent Render Loop
        * @param {Event} e The event object.
        * @returns {Void}
    */
    const prevent = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    /**
        * Handle Font Family Change
        * @param {Event} event The event object.
        * @returns {Void}
    */
    const handleFontFamilyChange = (event) => {
        setFontFamily(event.target.value);
    };

    /**
        * Handle Font Size Change
        * @param {Event} event The event object.
        * @returns {Void}
    */
    const handleFontSizeChange = (event) => {
        setFontSize(event.target.value);
    };

    /**
        * Handle Font Color Change
        * @param {Event} event The event object.
        * @returns {Void} The rendered Status Component.
    */
    const handleFontColorChange = (event) => {
        setFontColor(event.target.value);
    };

    /**
        * Handle Zoom In
        * @returns {Void}
    */
    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.1);
    };

    /**
        * Handle Zoom Out
        * @returns {Void} 
    */
    const handleZoomOut = () => {
        setZoomLevel(zoomLevel - 0.1);
    };

    /**
        * Draws a grid on the canvas.
        * 
        * This function draws a grid on the canvas using the specified grid size and 
        * color. It scales the grid based on the current zoom level to ensure that 
        * the grid remains consistent regardless of the zoom level.
        * 
        * @returns {void}
    */
    function drawGrid() {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.save(); // Save the current context state
        ctx.scale(zoomLevel, zoomLevel); // Apply the zoom level
        ctx.strokeStyle = gridColor;
        for (let x = 0; x <= canvas.width; x += gridSize) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvas.height);
            ctx.stroke();
        }
        for (let y = 0; y <= canvas.height; y += gridSize) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(canvas.width, y);
            ctx.stroke();
        }
        ctx.restore();
    }

    /**
        * Redraw Items
        * 
        * This function redraws all the items (rectangles, circles, images, and text) 
        * that have been drawn on the canvas. It iterates through the `drawnItems` array 
        * and uses the canvas context to draw each item based on its type and properties.
        * 
        * @returns {void}
    */
    function redrawItems() {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        console.log(drawnItems)
        drawnItems.forEach(item => {
            if (item.type === 'rectangle') {
                ctx.strokeStyle = fontColor;
                ctx.strokeRect(item.x, item.y, item.width, item.height);
            } else if (item.type === 'circle') {
                ctx.beginPath();
                ctx.arc(item.x, item.y, item.radius, 0, 2 * Math.PI);
                ctx.fillStyle = fontColor;
                ctx.stroke();
                ctx.closePath();
                console.log(item)
            } else if (item.type === 'image') {
                ctx.drawImage(item?.image, item?.x - item.radius, item?.y - item.radius, item?.width, item?.height);
            }
        });
    }


    /**
        * Save Item
        * 
        * This function adds a new item to the `drawnItems` array, which stores all the 
        * items that have been drawn on the canvas. The item is defined by its type 
        * (rectangle, circle, image, or text), coordinates (x, y), dimensions (width, 
        * height), radius (for circles), and image (if applicable).
        * 
        * @param {string} type The type of item being saved (rectangle, circle, image, or text).
        * @param {number} x The x-coordinate of the item.
        * @param {number} y The y-coordinate of the item.
        * @param {number} width The width of the item.
        * @param {number} height The height of the item.
        * @param {number} radius The radius of the item (for circles).
        * @param {Image} image The image object for image items.
        * 
        * @returns {void}
    */
    function saveItem(type, x, y, width, height, radius, image) {
        setDrawnItems([...drawnItems, { type, x, y, width, height, radius, image }]);
    }

    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        let isDrawing = false;
        let startX;
        let startY;
        let initialRadius;

        // console.log(image)
        // const image = new Image();
        // drawGrid();

        /**
           * Handle mouse down event
           * @param {MouseEvent} event - Mouse event
           * @returns {void}
       */
        const handleMouseDown = (event) => {
            prevent(event);
            const rect = canvas.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            let x = mouseX;
            let y = mouseY;

            // Snap to nearest grid point
            x = Math.round(x / gridSize) * gridSize;
            y = Math.round(y / gridSize) * gridSize;

            // Function to draw a circle
            function drawCircleCorner(x, y, radius) {
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, Math.PI * 2);
                ctx.fill();
            }

            // Check if the current tool is 'select'
            if (currentTool === 'select') {
                console.log(drawnItems)


                // Check if the mouse pointer is inside any existing drawn items
                for (let i = drawnItems.length - 1; i >= 0; i--) {
                    const item = drawnItems[i];


                    if (mouseX >= item.x && mouseX <= item.x + item.width &&
                        mouseY >= item.y && mouseY <= item.y + item.height || item.type === "text") {
                        // Item is selected
                        selectedItemId = i;
                        isDraggingItem = true;

                        if (item.type === "rectangle" || item.type === "text" || item.type === "image") {
                            // Check if the mouse pointer is near the corner of the item for resizing
                            const threshold = 10; // Adjust this value for the sensitivity of resizing
                            const cornerNW = { x: item.x, y: item.y };
                            const cornerNE = { x: item.x + item.width, y: item.y };
                            const cornerSE = { x: item.x + item.width, y: item.y + item.height };
                            const cornerSW = { x: item.x, y: item.y + item.height };

                            ctx.fillStyle = 'rgba(190, 190, 190, .6)';
                            // Draw circles at each corner
                            drawCircleCorner(cornerNW.x - 2.5, cornerNW.y - 2.5, 10); // Adjusting the center coordinates to make sure the circle is drawn at the corner
                            drawCircleCorner(cornerNE.x + 2.5, cornerNE.y - 2.5, 10);
                            drawCircleCorner(cornerSE.x + 2.5, cornerSE.y + 2.5, 10);
                            drawCircleCorner(cornerSW.x - 2.5, cornerSW.y + 2.5, 10);

                            if (Math.abs(mouseX - cornerNW.x) <= threshold && Math.abs(mouseY - cornerNW.y) <= threshold) {
                                isResizingItem = true;
                                resizeHandle = 'nw';
                            } else if (Math.abs(mouseX - cornerNE.x) <= threshold && Math.abs(mouseY - cornerNE.y) <= threshold) {
                                isResizingItem = true;
                                resizeHandle = 'ne';
                            } else if (Math.abs(mouseX - cornerSE.x) <= threshold && Math.abs(mouseY - cornerSE.y) <= threshold) {
                                isResizingItem = true;
                                resizeHandle = 'se';
                            } else if (Math.abs(mouseX - cornerSW.x) <= threshold && Math.abs(mouseY - cornerSW.y) <= threshold) {
                                isResizingItem = true;
                                resizeHandle = 'sw';
                            }
                            // Calculate offset between mouse position and item position
                            const offsetX = mouseX - item.x;
                            const offsetY = mouseY - item.y;
                            // Store offset for dragging
                            dragStartX = offsetX;
                            dragStartY = offsetY;
                            // Exit loop after finding the topmost selected item
                            break;

                        }

                    }
                }
            } else if (currentTool === 'text') {
                // Draw text
                ctx.font = `${fontSize}px ${fontFamily}`;
                ctx.fillStyle = fontColor;
                ctx.fillText(textValue, x, y);
                const textMetrics = ctx.measureText(textValue);
                const textWidth = textMetrics.width;
                const textHeight = fontSize;
                saveItem('text', x, y, textWidth, textHeight);
            }

            // Redraw grid after drawing shape
            // drawGrid();

        };

        /**
            * Handle mouse move event
            * @param {MouseEvent} event - Mouse event
            * @returns {void}
        */
        const handleMouseMove = (event) => {

            prevent(event);
            const rect = canvas.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            // console.log(isResizingItem)
            // console.log(selectedItemId)
            const resizeFactor = 0.01;


            // If an item is being resized
            if (isResizingItem && selectedItemId !== null) {
                const selectedItem = drawnItems[selectedItemId];
                const corner = { x: selectedItem.x + selectedItem.width, y: selectedItem.y + selectedItem.height };
                let deltaX = (corner.x - mouseX) * resizeFactor;
                let deltaY = (corner.y - mouseY) * resizeFactor;
                console.log(selectedItem)
                console.log(corner)
                console.log(deltaX)
                console.log(deltaY)

                if (resizeHandle === 'nw') {
                    selectedItem.x -= deltaX;
                    selectedItem.y -= deltaY;
                    selectedItem.width += deltaX;
                    selectedItem.height += deltaY;
                } else if (resizeHandle === 'ne') {
                    selectedItem.y -= deltaY;
                    selectedItem.width -= deltaX;
                    selectedItem.height += deltaY;
                } else if (resizeHandle === 'se') {
                    selectedItem.width -= deltaX;
                    selectedItem.height -= deltaY;
                } else if (resizeHandle === 'sw') {
                    selectedItem.x -= deltaX;
                    selectedItem.width += deltaX;
                    selectedItem.height -= deltaY;
                }
                redrawCanvas();
            }

            // If an item is being dragged
            else if (isDraggingItem && selectedItemId !== null) {
                const selectedItem = drawnItems[selectedItemId];
                selectedItem.x = mouseX - dragStartX;
                selectedItem.y = mouseY - dragStartY;
                redrawCanvas();
            }
            else if (isDrawing && currentTool === 'text') {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                drawGrid();
                redrawItems();
                ctx.font = `${fontSize}px ${fontFamily}`;
                ctx.fillStyle = fontColor;
                ctx.fillText(textValue, startX, startY, 50, 50);
                const textMetrics = ctx.measureText(textValue);
                const textWidth = textMetrics.width;
                const textHeight = fontSize;
                saveItem('text', startX, startY, textWidth, textHeight);
            }
        };

        /**
            * Handle mouse up event
            * @returns {void}
        */
        const handleMouseUp = () => {

            isDrawing = false;

            isDraggingItem = false;
            isResizingItem = false;
            resizeHandle = null;
            selectedItemId = null;
        };

        // Attach event listeners
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        // Clean up event listeners
        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
        };
    }, [currentTool, zoomLevel, fontFamily, fontSize, fontColor, textValue]);

    /**
        * Handle Clear Canvas
        * @returns {void}
    */
    const handleClearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        setDrawnItems([]);

        drawGrid();
    };

    /**
        * Handle Change Tool
        * @param {Event} event The event object.
        * @returns {void}
    */
    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    /**
        * Redraws the canvas
        * @returns {void}
    */
    const redrawCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.lineWidth = fontSize;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        redrawItems();

        ctx.lineWidth = 1;
        drawGrid();
    };

    return (
        <Toolbar
            sx={{
                display: "flex",
                flexWrap: 'wrap',
                gap: '1px',
                background: "#f5f5f5",
                borderRadius: "20px"
            }}
            className="typography-toolbar">
            <Typography>Color: </Typography>
            <Button size="small">
                <TextField size="small"
                    sx={{
                        minWidth: "50px"
                    }}
                    type="color" value={fontColor}
                    onChange={handleFontColorChange} />
            </Button>
            <Typography>Stroke: </Typography>
            <TextField
                size="small"
                type="number"
                value={fontSize}
                onChange={handleFontSizeChange}
                style={{
                    marginRight: '8px',
                    width: "120px"

                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">px</InputAdornment>,
                }}
            />
            <Divider
                variant="middle"
                sx={{
                    padding: "5px",
                    marginRight: "10px"

                }}
                orientation='vertical'
                flexItem />
            <Typography>Font: </Typography>
            <Select
                size="small"
                value={fontFamily}
                onChange={handleFontFamilyChange}
                sx={{ mr: 2 }}>
                <MenuItem value="Arial">Arial</MenuItem>
                <MenuItem value="Verdana">Verdana</MenuItem>
                <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            </Select>
            <TextField
                size="small"
                value={textValue}
                onChange={handleTextChange}
                label="Text"
                variant="outlined"
                sx={{ mr: 2 }} />
            <Divider
                variant="middle"
                sx={{
                    padding: "5px",
                    marginRight: "10px"
                }}
                orientation='vertical'
                flexItem />

            <Button
                size="small"
                startIcon={<DeleteIcon />}
                variant="outlined"
                onClick={handleClearCanvas}>
                Clear Canvas
            </Button>

            <Divider
                variant="middle"
                sx={{
                    padding: "5px",
                    marginRight: "10px"
                }}
                orientation='vertical'
                flexItem />

            <IconButton
                size="small"
                aria-label="Zoom In"
                onClick={handleZoomIn}>
                <ZoomInIcon />
            </IconButton>
            <IconButton
                size="small"
                aria-label="Zoom Out"
                onClick={handleZoomOut}>
                <ZoomOutIcon />
            </IconButton>
            <IconButton 
            size="small">
                <ContentCopyIcon />
            </IconButton>
        </Toolbar>
    )
}

export default VisualToolbar;