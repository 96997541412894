import React, { useEffect, useState } from "react";

// Material Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
// import GoogleIcon from '@mui/icons-material/Google';

// JWT
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// Axios API
import axiosInstance from "../utils/axiosInstance";

// Google Login API
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

// React Routes
import { useNavigate } from "react-router-dom";

// Images
import cstLogo from '../images/cst_logo_2.png';

// React Redux
import { useDispatch, useSelector } from "react-redux";
import { updateUser, updateEchoUser } from "../store/actions";
import { styled } from '@mui/material/styles';


const GoogleSignInButton = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff', // Google white background
    color: '#3c4043', // Google text color
    border: '1px solid #dcdcdc', // Light grey border
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '16px',
    fontWeight: '500',
    cursor: 'pointer',
    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
    transition: 'background-color 0.3s, box-shadow 0.3s',
    fontFamily: '"Roboto", sans-serif', // Google font
    '&:hover': {
        backgroundColor: '#f1f1f1', // Slightly darker grey on hover
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    },
}));

/**
    * Sign In Component
    * @param {Boolean} setAuthStatus Checks Google Response if User Email exists
    * @returns {JSX.Element} The rendered Sign In Component.
*/
const SignIn = ({ setAuthStatus, setCustomerStatus }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState([]);
    const [token, setToken] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [signInEmail, setSigninEmail] = useState("");
    const [signInPassword, setSigninPassword] = useState("");


    const currentUser = useSelector((state) => state.currentUser);

    /**
         * Initializes Google Login and handles the authentication process.
         * 
         * This function uses the `useGoogleLogin` hook from `@react-oauth/google` to 
         * initiate Google authentication. Upon successful login, it retrieves access 
         * tokens and user profile information from the backend API.
         * 
         * @param {object} props - The component's props.
         * @param {function} props.setAuthStatus - A function to update the authentication status.
         * @returns {JSX.Element} The rendered Sign In Component.
     */
    const googleLogin = useGoogleLogin({
        onSuccess: async ({ code }) => {
            try {
                // localStorage.removeItem("jwtToken");
                // localStorage.clear();
                // Cookies.remove('employeeToken');
                // Cookies.remove('customerToken');
                // Cookies.remove('chooseCustomerEmployee');
                // Cookies.remove('refreshToken');
                setToken("");

                Cookies.set("chooseCustomerEmployee", "employee")


                const tokens = await axiosInstance.post("/auth", {
                    code,
                });
                // console.log(tokens);
                const { token, profile, employeeToken, refreshToken } = tokens.data;


                setToken(token);
                setProfile(profile);
                // localStorage.setItem("jwtToken", employeeToken);
                // localStorage.setItem("refreshToken", refreshToken);
                Cookies.set("employeeToken", employeeToken);
                Cookies.set("refreshToken", refreshToken);

                console.log(tokens)
                const userJWT = jwtDecode(token);
                setUser(userJWT)
                console.log(tokens.data)
            } catch (error) {
                console.error('Error during Google login:', error);
                // Handle error (e.g., show an error message to the user)
            }
        },
        flow: 'auth-code',
    });

    const handleSignInCustomer = (e) => {
        e.preventDefault();

        const { name, value } = e.target;

        if (name === "signInEmail") {
            setSigninEmail(value);
            if (!isValidEmail(value)) {
                setEmailError("Invalid Email");
            } else {
                setEmailError("");
            }
        }

        if (name === "signInPassword") {
            setSigninPassword(value);
            if (!isValidPassword(value)) {
                setPasswordError("Password must be at least 8 characters long and contain at least one number");
            } else {
                setPasswordError("");
            }
        }
    }

    const isValidEmail = (email) => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailRegex = /^[^\s@]+@coldstoragetech\.com$/;
        return emailRegex.test(email);
    }

    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    }


    const handleSignInSubmit = async (e) => {

        e.preventDefault();

        if (!isValidEmail(signInEmail)) {
            setEmailError("Invalid Email");
            return;
        }

        if (!isValidPassword(signInPassword)) {
            setPasswordError("Password must be at least 8 characters long and contain at least one number");
            return;
        }

        if (emailError || passwordError) {
            alert('Please fix the errors before submitting');
            return;
        }

        try {
            // Cookies.remove('employeeToken');
            // Cookies.remove('customerToken');
            // Cookies.remove('chooseCustomerEmployee');
            // Cookies.remove('refreshToken');

            Cookies.set("chooseCustomerEmployee", "customer")

            const response = await axiosInstance.post("/auth/customer/login", {
                email: signInEmail,
                password: signInPassword,
            });
            const { customerToken, profile, refreshToken } = response.data;

            console.log(response.data);
            setProfile(profile);
            setCustomerStatus(true);
            Cookies.set("customerToken", customerToken);
            Cookies.set("refreshToken", refreshToken);
            await dispatch(
                updateEchoUser({
                    token: customerToken,
                    profile: profile,
                })
            );
            navigate("/echo/home");
        } catch (error) {
            console.error('Login failed', error);
            // Handle login failure (e.g., display error message)
            setCustomerStatus(false)

        }
    }

    useEffect(
        () => {
            if (token) {
                console.log("User authenticated");
                setAuthStatus(user?.email_verified)
                // console.log(token);
                console.log(user);
                console.log(profile);
                dispatch(
                    updateUser({
                        isAuthenticated: user?.email_verified,
                        token: token,
                        user: user,
                        profile: profile,
                    })
                );


                navigate("/hoth/overview");
            }

        },
        [token, navigate, setAuthStatus, setUser]
    );

    /**
        * log out function to log the user out of google and set the profile array to null
        * @param {}
        * @returns {Void}
    */
    const logOut = () => {
        googleLogout();
        setUser(null);
    };


    return (
        <Container>
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    padding: 3,
                    marginTop: 4,
                    borderRadius: "20px"
                }}>
                <div>
                    <Box align="center">
                        <Link href="/">
                            <img
                                src={cstLogo}
                                alt="cst logo"
                                width="300" />
                        </Link>
                    </Box>
                    <Grid
                        container
                        spacing={5}>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>

                            <Typography
                                variant="h2"
                                align="center">Customer Login
                            </Typography>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                type="email"
                                label="Email"
                                name="signInEmail"
                                value={signInEmail}
                                error={emailError ? true : false}
                                helperText={emailError}
                                onChange={handleSignInCustomer}
                            />
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                type="password"
                                label="Password"
                                name="signInPassword"
                                value={signInPassword}
                                error={passwordError ? true : false}
                                helperText={passwordError}
                                onChange={handleSignInCustomer}
                            />
                            <Button
                                style={{ marginTop: 40 }}
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={handleSignInSubmit}>
                                Submit
                            </Button>
                        </Grid>
                        <Grid
                            size={{
                                md: 6,
                                xs: 6
                            }}>

                            <Typography
                                variant="h2"
                                align="center">Employee Login</Typography>

                            <br />
                            <br />
                            <Box style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }} >

                                <GoogleSignInButton
                                    fullWidth
                                    size="large"
                                    variant="outlined"
                                    onClick={googleLogin}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                // onClick={googleLogin}
                                // useOneTap 
                                >
                                    <div style={{ marginRight: 16 }}>
                                        <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 48 48"
                                            style={{ display: 'block', width: 24, height: 24 }}
                                        >
                                            <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                            <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                            <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                            <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                            <path fill="none" d="M0 0h48v48H0z"></path>
                                        </svg>
                                    </div>
                                    <span>Sign in with Google</span>
                                </GoogleSignInButton>
                            </Box>
                        </Grid>
                        {/* {token ? (
                        <div>
                            <img src={user.picture} alt="user image" />
                            <h3>User Logged in</h3>
                            <p>Name: {user.name}</p>
                            <p>Email Address: {user.email}</p>
                            <br />
                            <br />
                            <button onClick={logOut}>Log out</button>
                        </div>
                    ) : (
                        <Button size="large" onClick={googleLogin}>Sign in with Google</Button>
                    )} */}
                    </Grid>

                </div>
            </Paper>
        </Container>
    );
};

export default SignIn;