import { configureStore } from "@reduxjs/toolkit";
import currentUserReducer from "./reducers/currentUser";
import echoReducer from "./reducers/echoUser";

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    echoUser: echoReducer,
  },
  devTools: true, // Enable Redux DevTools extension
});