import React, { useEffect, useState, useRef } from 'react';

// Graphs
import ApexCharts from 'apexcharts';




function MonitorChart() {

    const uptimeChart = useRef(null);
    let chartInstance = useRef(null);

    const [chart, setChart] = useState(null);

    const [time, setGraphTime] = useState("24");

    // const chartRef2 = useRef(null);
    // const chartRef3 = useRef(null);

    const [data, setData] = useState([]);

    const [simUpCount, setSimUpCount] = useState(0);
    const [totalIterations, setTotalIterations] = useState(0);
    const [status, setStatus] = useState(null);



    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString();
            const simUp = Math.random() > 0.1;
            const uptimeValue = 1; // Always set uptimeValue to 1
            const newDataPoint = {
                x: currentTime,
                y: uptimeValue,
                downtime: calcPercent(),
                fillColor: simUp ? '#2de377' : '#fc5842',
            };

            setStatus(simUp ? 'Operational' : 'Down');

            setData((prevData) => {
                const updatedData = [...prevData.slice(-29), newDataPoint]; // Keep the array length at 30
                chartInstance.current.updateSeries([{ data: updatedData }]);
                return updatedData;
            });

            // Update counts efficiently
            setTotalIterations((prev) => prev + 1);
            if (simUp) {
                setSimUpCount((prev) => prev + 1);
            }
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const options = {
            chart: {
                type: 'bar',
                height: 180,
                foreColor: "white"
            },
            xaxis: {
                categories: getPast30DaysHours(),
                reversed: true,
                title: {
                    text: 'Total downtime in minutes.' + simUpCount + 'Outages detected.',
                    style: {
                        textAlign: 'center',
                    },
                },
                max: time,
            },
            yaxis: {
                show: false,
                max: 1,
            },
            grid: {
                show: false,
            },
            series: [
                {
                    name: 'Uptime',
                    data: [],
                    color: '#777',
                },
            ],
            tooltip: {
                theme: 'dark',  // Dark theme for tooltips
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        enabled: true,
                        formatter: function (val, opts) {
                            const seriesData = opts.w.globals.series[opts.seriesIndex];
                            const dataPoint = seriesData && seriesData[opts.dataPointIndex];  // Check if seriesData exists

                            if (dataPoint && dataPoint.fillColor) {  // Check if dataPoint and fillColor are defined
                                return dataPoint.fillColor === '#2de377' ? 'Green' : 'Red';
                            } else {
                                return 'N/A';  // Fallback for undefined or null values
                            }
                        },
                        style: {
                            color: '#fff',
                        },
                    },
                },
            },
        };


        // if (uptimeChart.current) {
        //     const newChart = new ApexCharts(uptimeChart.current, options);
        //     newChart.render();
        //     setChart(newChart);
        // }

        try {
            if (!chartInstance.current) {

                chartInstance.current = new ApexCharts(uptimeChart.current, options);
                chartInstance.current.render();
                setChart(chartInstance);
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chartInstance.current) {
            //     chartInstance.current.destroy();
            //     chartInstance.current = null; // Clear the reference after destruction
            // }
        };
    }, [uptimeChart.current]);

    function getPast30DaysHours() {
        const categories = Array.from({ length: 30 }, (_, i) => {
            const date = new Date();
            date.setDate(date.getDate() - (29 - i));
            return `${date.toLocaleDateString()}`;
        });
        return categories;
    }


    function calcPercent() {
        if (totalIterations === 0) return 0;
        return ((simUpCount / totalIterations) * 100).toFixed(2);
    }

    return (
        <>
            {calcPercent()}%
            <div
                data-testid="monitorChart"
                ref={uptimeChart}  // Replace with another chart reference
                style={{ height: '180px' }}
            />
        </>
    )
}

export default MonitorChart;