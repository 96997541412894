import React from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import { List, ListItem, ListItemText, Paper } from '@mui/material';

const logData = [
    { id: 1, timestamp: '2024-09-10T10:30:00', message: 'Critical: System down' },
    { id: 2, timestamp: '2024-09-10T11:00:00', message: 'Warning: High CPU usage' },
    { id: 3, timestamp: '2024-09-10T11:15:00', message: 'Error: Failed login attempt' },
];

/**
 * Logs Component
 * @returns {JSX.Element} The rendered Logs Component.
 */
function Logs() {



    return (
        <Grid
            container
            padding={2}
            spacing={5}
            sx={{
                background: "#141422"
            }}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: '20px',
                        background: "#242436",
                        color: "white"
                    }}>
                    <CardContent>
                        <Typography>
                            Logs
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                            <Paper elevation={3} sx={{ background: "#242436", maxHeight: 400, overflow: 'auto', padding: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                                    System Logs
                                </Typography>
                                <List>
                                    {logData.map((log) => (
                                        <ListItem key={log.id}>
                                            <ListItemText
                                                sx={{ color: '#fff' }}
                                                primary={log.message}
                                                secondary={new Date(log.timestamp).toLocaleString()}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 6,
                    xs: 6
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>
            <Grid
                size={{
                    md: 3,
                    xs: 3
                }}>
                <Container>
                    <Card
                        sx={{
                            borderRadius: '20px',
                            background: "#242436",
                            color: "white"
                        }}>
                        <CardContent>

                        </CardContent>
                    </Card>
                </Container>
            </Grid>

        </Grid>
    );
}

export default Logs;
