
// Material UI Components
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

function NoPage() {
    return (
        <Card>
            <Typography varaint="h1" role="heading">
                No Page
            </Typography>
        </Card>
    )
}

export default NoPage;