import React, { useEffect, useRef } from 'react';

import ApexCharts from 'apexcharts';

function KPIChart() {
    const kpiChart = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        const options = {
            chart: {
                type: 'bar',
                height: 250,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            yaxis: {
                title: {
                    text: 'Cost',
                },
            },
            xaxis: {
                categories: ['Item A', 'Item B', 'Item C', 'Item D', 'Item E'],
            },
            fill: {
                opacity: 1,
            },
        };

        const series = [{
            name: 'Quantity in Stock',
            data: [44, 55, 41, 64, 22],
        }];

        try {
            if (!chartInstance.current) {
                chartInstance.current = new ApexCharts(kpiChart.current, { series, ...options });
                chartInstance.current.render();
            }
        } catch (error) {
            console.error('Error rendering chart:', error);
        }

        return () => {
            // if (chartInstance.current) {
            //     chartInstance.current.destroy();
            //     chartInstance.current = null; // Clear the reference after destruction
            // }
        };
    }, [kpiChart.current]);
    return (
        <div
            data-testid="kpiChart"
            ref={kpiChart}
            style={{
                height: '100px'
            }} />
    )
}

export default KPIChart;