
// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

// Images
import newConstructionImage from '../../images/newconstruction.gif';
import cstConstruction from '../../images/cst_home/cst_construction.jpg';

function Home() {
    return (
        <Box>
            <Grid container>
                <Grid
                    size={{
                        md: 12,
                        xs: 12
                    }}>
                    <Card
                        elevation={0}
                        sx={{
                            color: 'white',
                            background: 'linear-gradient(353deg, #283f99, #2377d5)',
                            // borderRadius: "20px"
                        }}>
                        <CardContent>
                            <Container>
                                <Typography variant="h1">
                                    New Construction
                                </Typography>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Container>
                <Grid
                    container
                    padding={10}
                    spacing={5}>

                    <Grid
                        size={{
                            md: 8,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardContent>
                                <Typography
                                    variant="h3"
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    Cold Storage Technologies includes innovative application of technology to meet the required food safety standards and maximize profits with an environmentally sensitive “green” approach in California.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        size={{
                            md: 4,
                            xs: 12
                        }}>
                        <Card elevation={0}>
                            <CardMedia
                                component="img"
                                height="200"
                                image={newConstructionImage}
                                sx={{
                                    padding: "1em 1em 0 1em",
                                    objectFit: "contain"
                                }}
                                alt="new construction">
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h4">
                                </Typography>
                                <Typography variant='h6'>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Box>
                        <Container>
                            <Box sx={{ padding: "100px" }}>
                                <Divider
                                    sx={{
                                        height: "5px",
                                        background: "linear-gradient(to left, #375fd7 50%, #afd5ff 50%)"
                                    }}></Divider>
                            </Box>
                            <Typography
                                variant='h2'
                                sx={{
                                    fontFamily: 'IBM Plex Sans',
                                    fontWeight: 600,
                                    textAlign: "center",
                                    padding: "100px"
                                }}>
                                <span className='gradientGlow'>
                                    This green approach provides media opportunities, carbon credits and utility incentives.
                                </span>
                            </Typography>
                        </Container>
                        <Box sx={{ padding: "100px" }}>
                            <Divider
                                sx={{
                                    height: "5px",
                                    background: "linear-gradient(to right, #375fd7 50%, #afd5ff 50%)"
                                }}></Divider>
                        </Box>
                        <img src={cstConstruction} width={'100%'} alt="construction" />

                    </Box>
                </Grid>
            </Container>

            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Box sx={{
                    background: "linear-gradient(5deg, #283f99, #4686cd)",
                    color: " white"
                }}>
                    <Container>
                        <Typography
                            variant='h3'
                            sx={{
                                fontFamily: 'IBM Plex Sans',
                                fontWeight: 600,
                                textAlign: "center",
                                padding: "100px"
                            }}>
                            Our typical design exceeds Title 24 energy efficiency standards and includes an integrated Federal EPA Risk Management Plan.
                        </Typography>
                    </Container>
                </Box>
            </Grid>
            <Container>
                <Grid container>
                    <Grid
                        padding={20}
                        size={{
                            md: 12,
                            xs: 12
                        }}>
                        <Card
                            sx={{
                                borderRadius: "20px",
                                padding: "50px"
                            }}
                            elevation={0}
                            variant="outlined">
                            <CardContent>
                                <Typography variant="h4"
                                    style={{
                                        fontFamily: "IBM Plex Sans",
                                        fontWeight: 600
                                    }}>
                                    Our design and contracting approach includes the following unique features;
                                </Typography>
                                <Typography variant='h5'>
                                    <List
                                        sx={{
                                            listStyleType: 'disc',
                                            padding: '20px'
                                        }}>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Multiple suction operating pressures
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Ammonia Refrigeration piping and infrastructure designed for future expansions
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Thermo-syphon oil cooling
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Oil pump free compressor design.
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Capture Complete Evaporator Pans
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Frost minimizing coil design
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Motorized Back-Pressure-Regulators

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Variable Frequency Drives on fans

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Hot gas Defrost

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Multiple Accuair units to reduce temperature increase in process areas and provide air balance and movement to prevent contamination.

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Redundant controls and Graphical User Interface Refrigeration Control System that allows Ethernet remote monitoring and paging alarms.

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            Post Weld Heat Treating on all vessels

                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: 'list-item'
                                            }}>
                                            We cover the entire central valley of California

                                        </ListItem>
                                    </List>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Home;