
import React, { useEffect } from 'react';

// Material UI Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

/**
    * Policies Component
    * @returns {JSX.Element} The rendered Policies Component.
*/
function Policies() {

    useEffect(() => {

    }, [])
    return (
        <Grid
            container
            padding={2}
            spacing={5}>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Card
                    elevation={0}
                    variant="outlined"
                    sx={{
                        borderRadius: "20px"

                    }}>
                    <CardContent>
                        <Typography variant="h3">
                            Policies
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid
                size={{
                    md: 12,
                    xs: 12
                }}>
                <Container>
                    <Typography variant="h1">
                        Safety
                    </Typography>
                    <Typography variant="h1">
                        Training
                    </Typography>
                </Container>
            </Grid>
        </Grid>
    )
}

export default Policies;